<template>
    <div  class="main filter-height">
        <div class="template-width">
            <h3 class="preference-header">
                Content preferences: Family-friendly
                <i 
                    class="tooltip-icon" 
                    @mouseover="showTooltip('familyFriendly')"
                    @mouseleave="hideTooltip('familyFriendly')">
                    <font-awesome-icon icon="fa-solid fa-info-circle"/>
                </i>
            </h3>
            <div v-if="tooltips.familyFriendly" class="tooltip-content">
                Obscenity refers offensive or vulgar language, while toxicity includes harassment, insults. Streamers in the "high" category fall into the top percentiles, indicating they frequently use higher levels of offensive language. Conversely, those in the "low" category are in the bottom percentiles, reflecting minimal to no use of such content. 
            </div>
            <div class="hint">Adjust the maximum permissible levels of obscenity and toxicity in the streamer's content</div>

            <div class="row-flex-container">

                <div class="column-flex-container">
                    <label for="filterObscenityLevel" class="reach-label">
                        Obscenity Level {{ this.obsceneEmoji.repeat(Math.round(filterObscenityLevel/30)+1) }}
                    </label>
                    <div class="slider-wrapper">
                        <div class="slider-container">
                            <input type="range" v-model="filterObscenityLevel" min="0" max="100" step="10" class="slider" :style="`--val:${filterObscenityLevel}`" />
                            <div class="labels">
                                <span class="label">Low</span>
                                <span v-for="(spacer, index) in sliderSpacers" :key="index" class="label" style="flex: 1;"></span>
                                <span class="label">High</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="column-flex-container">
                    <label for="filterToxicityLevel" class="reach-label">
                        Toxicity Level {{ this.toxicEmoji.repeat(Math.round(filterToxicityLevel/30)+1) }}
                    </label>
                    <div class="slider-wrapper">
                        <div class="slider-container">
                            <input type="range" v-model="filterToxicityLevel" min="0" max="100" step="10" class="slider" :style="`--val:${filterToxicityLevel}`" />
                            <div class="labels">
                                <span class="label">Low</span>
                                <span v-for="(spacer, index) in sliderSpacers" :key="index" class="label" style="flex: 1;"></span>
                                <span class="label">High</span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
        
        <div class="template-width" style="padding-top: 25px;">
            <div class="row-flex-container">

                <div style="width: 100%;">
                    <div class="icon-with-tooltip-container">
                        <h3 class="preference-header">
                            Reach: Concurrent viewers
                            <i
                                @mouseover="showTooltip('concurrentViewers')"
                                @mouseleave="hideTooltip('concurrentViewers')"
                                class="tooltip-icon">
                                <font-awesome-icon icon="fa-solid fa-info-circle"/>
                            </i>
                        </h3>
                        <div v-if="tooltips.concurrentViewers" class="tooltip-content">
                            The real-time count of actively watching individuals during a live stream, indicating the current audience size for a specific Twitch channel. 
                        </div>
                    </div>
                    <div class="row-flex-container">
                        <input 
                            v-model="minViews" 
                            type="number" 
                            placeholder="Min Views" 
                            class="reach-input-box" 
                            />
                        <input 
                            v-model="maxViews" 
                            type="number" 
                            placeholder="Max Views" 
                            class="reach-input-box" 
                            />
                    </div>
                </div>

                <div style="width: 100%;">
                    <h3 class="preference-header">
                        Reach: Twitch followers
                    </h3>
                    <div class="row-flex-container">
                        <input 
                            v-model="minFollowers" 
                            type="number" 
                            placeholder="Min Followers" 
                            class="reach-input-box"
                            />
                        <input 
                            v-model="maxFollowers" 
                            type="number" 
                            placeholder="Max Followers" 
                            class="reach-input-box"
                            />
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "ChannelCharacteristicsFilter",
    data () {
        return {
            sliderSpacers: Array(3).fill(null),
            toxicEmoji: '☢️ ',//'💥',
            obsceneEmoji: '🤬 ',

            tooltips: {
                familyFriendly: false,
                concurrentViewers: false,
            },

            // TBD - these might be useful, but currently out of scope
            filter_selectedEmotions: [],
            positiveEmotionList: ['Admiration 😍', 'Amusement 😄', 'Approval 👍', 'Caring ❤️', 'Curiosity 🤔', 'Desire 🔥', 'Excitement 🎉', 'Gratitude 🙏', 'Joy 😃', 'Love ❤️', 'Optimism 😊', 'Pride 🏆', 'Realization 💡', 'Relief 😅', 'Surprise 😮'],
            negativeEmotionList: ['Anger 😠', 'Annoyance 😒', 'Confusion 😕', 'Disappointment 😞', 'Disapproval 👎', 'Disgust 🤢', 'Embarrassment 😳', 'Fear 😨', 'Grief 😢', 'Nervousness 😰', 'Remorse 😔', 'Sadness 😔'],

        }
    },
    methods: {
        showTooltip(tooltipKey) {
            this.tooltips[tooltipKey] = true;
        },
        hideTooltip(tooltipKey) {
            this.tooltips[tooltipKey] = false;
        }
    },
    computed: {
        // handle updates to filters
        filterObscenityLevel: {
            get() {
                return this.$store.state.filterState.obscenityLevel;
            },
            set(value) {
                this.$store.commit('updateObscenityLevel', parseInt(value, 10));
            }
        },
        filterToxicityLevel: {
            get() {
                return this.$store.state.filterState.toxicityLevel;
            },
            set(value) {
                this.$store.commit('updateToxicityLevel', parseInt(value, 10));
            }
        },
        minFollowers: {
            get() {
                return this.$store.state.filterState.minFollowers;
            },
            set(value) {
                this.$store.commit('updateFollowerFilter', {
                ...this.$store.state.filterState,
                minFollowers: value
                });
            }
            },
        maxFollowers: {
            get() {
                return this.$store.state.filterState.maxFollowers;
            },
            set(value) {
                this.$store.commit('updateFollowerFilter', {
                ...this.$store.state.filterState,
                maxFollowers: value
                });
            }
        },
        minViews: {
            get() {
                return this.$store.state.filterState.minViews;
            },
            set(value) {
                this.$store.commit('updateViewerFilter', {
                ...this.$store.state.filterState,
                minViews: value
                });
            }
            },
        maxViews: {
            get() {
                return this.$store.state.filterState.maxViews;
            },
            set(value) {
                this.$store.commit('updateViewerFilter', {
                ...this.$store.state.filterState,
                maxViews: value
                });
            }
        }
    },
}
</script>

<style scoped>
@import '@/assets/styles/filterViews.css';

.main {
    display: flex;
    flex-direction: column; 
    justify-content: center; 
    align-items: center;
    height: 100%;
    width: 100%          
}

.column-flex-container {
    display: flex;
    flex-direction: column;
    flex: 1;
}
.row-flex-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between; 
    gap: 25px;
}

.preference-header {
  font-size: 1.1rem;
  margin-bottom: 0.9rem;
  margin-top: 0.9rem;
}
.template-width {
    width: 80%
}

.tooltip-icon:hover {
  cursor: pointer;
}

.reach-label {
  font-size: 0.9rem;
  margin-bottom: 5px;
}

.hint {
    font-size: 0.9rem;
    padding-bottom: 15px;
}
/* viewers & followers filters */
.reach-input-box {
  padding: 15px 10px 15px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  font-size: 0.85rem;
  transition: border-color 0.2s;
  width: 100%;
}

.reach-input-box::placeholder {
  color: #888;
}
.reach-input-box:focus {
  border-color: #42b983;
}

/* slider formatting */
.slider-wrapper {
  font-family: 'Helvetica Neue', Arial, sans-serif;
  color: #333;
  text-align: center;
}

.slider-container {
  position: relative;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 10px;
  border-radius: 12px;
  background: #ddd;
  outline: none;
  transition: opacity .2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  margin-top: -5px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #42b983;
  cursor: pointer;
  transition: background .3s ease-in-out;
}

.slider::-webkit-slider-thumb:hover {
  background: rgb(19, 75, 95);
}

.labels {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-top: 10px;
  color:grey;
}

.label {
  flex: 0 0 auto;
  text-align: center;
  font-size: 0.8rem;
}

.value-display {
  margin-top: 20px;
  font-size: 1rem;
  color: #555;
}
.slider::-webkit-slider-runnable-track {
    background: linear-gradient(to right, #42b983 0%, #42b983 calc((100% / 100) * var(--val)), lightgray calc((100% / 100) * var(--val)), lightgray 100%);
    height: 10px;
    border-radius: 12px;
}

/* TOOLTIP FORMATTING */
.tooltip-content {
    position: absolute;
    font-size: 0.9rem;
    left: 50%; /* Start at the middle of the icon */
    transform: translateX(-50%); /* Center it above the icon */

    /* Styling for the tooltip */
    background-color: black;
    color: white;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    z-index: 10; /* Ensure it's above other content */
}

</style>