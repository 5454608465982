import { createApp } from 'vue'
import { createStore } from 'vuex'
import App from './App.vue'
import router from './router'
import VTooltip from 'v-tooltip'

import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
/* import specific icons */
import { faMagnifyingGlass, faHouse, faWandMagicSparkles, faGear, faInfoCircle, faEraser, faArrowLeft, faArrowRight, faEye, faPlus, faCircleDot, faHeart, faCalendarCheck, faHourglassHalf, faMicrophoneLines, faComments, faUsers, faBan, faQuestionCircle, faSquarePlus, faSquareMinus, faSquareXmark, faBars, faCaretDown, faCaretUp, faSlidersH, faKeyboard, faCheckCircle, faTimesCircle, faGamepad, faMagnifyingGlassChart, faSquareCheck, faCircleChevronLeft, faChevronLeft, faChevronRight, faCheck, faArrowRightLong, faCircle} from '@fortawesome/free-solid-svg-icons'
import { faTwitter, faTwitch, faTiktok, faInstagram, faYoutube, faDiscord } from '@fortawesome/free-brands-svg-icons'
/* add icons to the library */
library.add(faMagnifyingGlass, faHouse, faWandMagicSparkles, faGear, faInfoCircle, faEraser, faArrowLeft, faArrowRight, faEye, faPlus, faCircleDot, faHeart, faCalendarCheck, faHourglassHalf, faMicrophoneLines, faComments, faUsers, faBan, faQuestionCircle, faSquarePlus, faSquareMinus, faSquareXmark, faBars, faCaretDown, faCaretUp, faSlidersH, faKeyboard, faCheckCircle, faTimesCircle, faGamepad, faSquareCheck, faCircleChevronLeft, faCheck, faArrowRightLong)
library.add(faTwitter, faTwitch, faTiktok, faInstagram, faYoutube, faDiscord, faMagnifyingGlassChart, faChevronLeft, faChevronRight, faCircle)
library.add(fas, far)

export const store = createStore({
    state () {
      return {
        showDefaultFilterScreen: true,
        selectedStreamerDetails: {
          "streamerKpiData": [{
            "topStreamCategories": [],
            "topWordsInStream": [],
            "streamVibes": {},
          }]
        },
        twitchPercentileBenchmarks: {},
        filterState: {
          suggestedPersonalities: Object.values([]),
          selectedPersonalities: [],
          selectedTwitchCategories: [],
          obscenityLevel: 100,
          toxicityLevel: 100,
          minFollowers: '',
          maxFollowers: '',
          minViews: '',
          maxViews: '',
          filter_channel_name: '',
        }
      }
    },
    mutations: {
        selectStreamerDetails (state, streamerDetails) {
            state.selectedStreamerDetails = streamerDetails
        },
        addPercentileData (state, percentileData) {
            state.twitchPercentileBenchmarks = percentileData
        },
        // filter values
        updateSelectedPersonalities (state, personalities) {
            state.filterState.selectedPersonalities = personalities
        },
        updateSuggestedPersonalities (state, personalities) {
          state.filterState.suggestedPersonalities = personalities
      },
        updateSelectedTwitchCategories (state, categories) {
            state.filterState.selectedTwitchCategories = categories
        },
        updateObscenityLevel (state, level) {
            state.filterState.obscenityLevel = level
        },
        updateToxicityLevel (state, level) {
          state.filterState.toxicityLevel = level
        },
        updateFollowerFilter (state, data) {
          state.filterState.minFollowers = data.minFollowers
          state.filterState.maxFollowers = data.maxFollowers
        },
        updateViewerFilter (state, data) {
          state.filterState.minViews = data.minViews
          state.filterState.maxViews = data.maxViews
        },
        SET_FILTER_CHANNEL_NAME (state, name) { 
          state.filterState.filter_channel_name = name
        },
        changeDefaultFilterScreenVisibility (state, is_visible) {
          state.showDefaultFilterScreen = is_visible
        }
    }
  })

const app = createApp(App)
app.component('font-awesome-icon', FontAwesomeIcon)
app.use(store)
app.use(router)
app.use(VTooltip)
app.mount('#app')


