import { createRouter, createWebHistory } from "vue-router"
import Campaign from '../components/Campaign.vue'
import Streamers from '../components/StreamerCardView.vue'
import Home from '../components/Home.vue'

const routes = [
    { 
        path: '/campaign', 
        name: 'Campaign',
        component: Campaign 
    },
    { 
        path: '/', 
        name: 'Home',
        component: Home
    },
    { 
        path: '/streamers', 
        name: 'Streamers',
        component: Streamers
    }
  ]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior() {
        // Always scroll to top for all route navigations
        return { top: 0 };
    }
})

export default router