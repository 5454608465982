<!-- StreamerDetailModule.vue -->

<template>
    <div class="modal-overlay">
      <div class="close-button-container">
          <button @click="closeModal"><span><font-awesome-icon :icon="['fas', 'arrow-left']" class="fa-xs"/></span> Back</button>
      </div>
      <div class="modal-content">

        <div class="container" style="padding-left: 10px;">
          <div class="container" style="flex:1">
            <img v-bind:src="streamer.CHANNEL_ICON_URL" class="streamer-profile-icon"/>
            <h2>{{ streamer.CHANNEL_NAME }}</h2>
          </div>
          <div class="container" style="flex:1">
            <div style="flex:1; font-size: 1rem"><span><font-awesome-icon :icon="['fas', 'heart']" class="fa-xs"/></span> {{ formatNumber(streamer.TOTAL_FOLLOWERS) }}</div>
            <div style="flex:1; font-size: 1rem"><span><font-awesome-icon :icon="['fas', 'eye']" class="fa-xs"/></span> {{ formatNumber(streamer.AVG_VIEWERS_90D) }}</div>
          </div>
          <div class="container" style="flex:2">
            <a :href="`https://www.twitch.tv/${streamer.CHANNEL_NAME}`" target="_blank" rel="noopener noreferrer" class="open-channel-link">
              <span>Open Twitch channel</span> <font-awesome-icon :icon="['fab', 'twitch']" class="twitch-icon"/>
            </a>
          </div>
          <div class="container" style="padding-right: 6px;">
            <div class="metric-definitions-button" @click="isTooltipCreatorPersonalityVisible= !isTooltipCreatorPersonalityVisible">Show metric definitions <span><i title="Click to view definitions">
                <font-awesome-icon icon="fa-solid fa-info-circle" class="tooltip-icon" style="color: #251101;"/>
            </i></span></div>
          </div>
        </div>

        <div v-if="isTooltipCreatorPersonalityVisible" class="tooltip-content">
          <ul>
              <li><strong>Unique Trait:</strong> Identifies the most distinctive personality trait of the creator as perceived in their Twitch streams.</li>
              <li><strong>Personality traits:</strong> The five most common traits exhibited by the creator during their streams.</li>
              <li><strong>Emotional intensity:</strong> Reflects the most frequently displayed emotions by the creator during their streams.</li>
              <li><strong>Sentiment:</strong> Measures the overall emotional tone of the creator's content as perceived through their language and expressions.</li>
              <li><strong>Profanity level:</strong> Measures the frequency of swear words and inappropriate language used by the creator, per hour of streaming, indicating the explicitness of the content.</li>
              <li><strong>Toxicity level:</strong> Assesses the rate of toxic behavior (e.g. abusive language and harassment), per hour of streaming, reflecting the stream's overall positive or negative environment.</li>
          </ul>
        </div>

        <div class="card-container">

          <div class="card">
            <div class="center-content" style="padding-top:7px; padding-bottom: 7px;">
                  <div class="card-section-subtitle" style="color: #F7ECE1; font-size: 1.2rem; background-color: #251101; width:96%; border-radius: 6px; padding-top:7px; padding-bottom: 7px;">Key metrics</div>
            </div>
            <div class="center-content">
                  <p class="card-section-subtitle subtitle-box">Content volume</p>
            </div>
            <div class="metrics-container">
                <div class="metrics-sub-container">
                    <div class="metrics-inner-div" style="font-weight: bold;font-size: 1.1rem;">{{ formatDecimal(streamer.STREAMS_PER_WEEK_90D) }}</div>
                    <div class="metrics-inner-div" style="padding: 0px;">Weekly streams</div>
                </div>
                <div class="metrics-sub-container">
                    <div class="metrics-inner-div" style="font-weight: bold;font-size: 1.1rem;">{{ formatTime(streamer.AVG_STREAM_DURATION_SEC_90D) }}</div>
                    <div class="metrics-inner-div" style="padding: 0px;">Stream duration (average)</div>
                </div>
            </div>
            <div class="center-content" style="padding-top: 20px;" v-if="!categoriesLoading">
                  <p class="card-section-subtitle subtitle-box">Top content categories</p>
            </div>
            <div class="metrics-container"  v-if="!categoriesLoading">
              <div class="metrics-sub-container">
                <table class="custom-table">
                  <tr v-for="game in Object.keys(streamer.TOP5_GAMES).slice(0, 3)" v-bind:key="game">
                    <td style="width: 5%"><span class="game-value" style="border: none;">{{ game.slice(-1) }}</span></td>
                    <td style="width: 80%;font-size: 1rem;"><span class="game-value" style="border: none; min-width: 90%; background-color: #FCFAFA;">{{ streamer.TOP5_GAMES[game] }}</span></td>
                    <td style="width: 15%;font-size: 1rem;">
                      <span v-if="isGameInResults(streamer.TOP5_GAMES[game])"><img :src="getThumbnailImg(streamer.TOP5_GAMES[game])"></span>
                      <span v-else><img src="https://static-cdn.jtvnw.net/ttv-static/404_boxart-68x95.jpg"></span>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="center-content" style="padding-top:7px; padding-bottom: 7px;">
                  <div class="card-section-subtitle" style="color: #F7ECE1; font-size: 1.2rem; background-color: #251101; width:96%; border-radius: 6px; padding-top:7px; padding-bottom: 7px;">Content safety</div>
            </div>
            <div class="center-content">
                  <p class="card-section-subtitle subtitle-box">Family friendly</p>
            </div>
            <div class="metrics-container">
                <div class="metrics-sub-container" style="padding-bottom: 0px;">
                    <div class="metrics-inner-div" style="font-weight: bold;font-size: 1.1rem;">
                      <span class="percentile-badge tooltip" :class="getPercentileClass(streamer.OBSCENITY_PER_HOUR, '4')">
                        {{ findPercentileForValue(this.$store.state.twitchPercentileBenchmarks, streamer.OBSCENITY_PER_HOUR,'4') }}
                        <span class="tooltiptext">Relative score to other creators. Higher score = more profanity</span>
                      </span>
                    </div>
                    <div class="metrics-inner-div">🤬 Profanity level</div>
                </div>
                <div class="metrics-sub-container" style="padding-bottom: 0px;">
                    <div class="metrics-inner-div" style="font-weight: bold;font-size: 1.1rem;">
                      <span class="percentile-badge tooltip" :class="getPercentileClass(streamer.TOXICITY_PER_HOUR, '5')">
                        {{ findPercentileForValue(this.$store.state.twitchPercentileBenchmarks, streamer.TOXICITY_PER_HOUR,'5') }}
                        <span class="tooltiptext">Relative score to other creators. Higher score = more toxicity</span>
                      </span>
                    </div>
                    
                    <div class="metrics-inner-div">☢️ Toxicity level</div>
                </div>
            </div>

            <div class="center-content" style="padding-top: 20px;">
                  <p class="card-section-subtitle subtitle-box">Sentiment</p>
            </div>

            <div class="metrics-container">
              <div class="metrics-sub-container">
                <table class="custom-table">
                  <tr v-for="sentiment in streamer.AVG_STREAM_SENTIMENT.calculated_metrics" v-bind:key="sentiment.label" style="height: 35px;">
                    <td style="width:30%; font-size: 0.9rem;" v-if="sentiment.label==='NEU' || sentiment.label==='NEG' || sentiment.label==='POS'">
                      <span v-if="sentiment.label === 'NEU'"><font-awesome-icon :icon="['far', 'face-meh-blank']" style="color: rgb(180, 180, 180)"/></span>
                      <span v-if="sentiment.label === 'POS'"><font-awesome-icon :icon="['far', 'face-smile']" style="color:#42b983"/></span>
                      <span v-if="sentiment.label === 'NEG'"><font-awesome-icon :icon="['far', 'face-frown']" style="color: #ff4d4d"/></span>
                      {{ sentiment.label === 'NEU' ? 'Neutral' : sentiment.label === 'NEG' ? 'Negative' : 'Positive' }}
                    </td>
                    <td style="width:10%; font-size: 0.7rem; text-align: center;">{{ Math.round(sentiment.sentiment_avg * 100) + '%' }}</td>
                    <td style="width:60%">
                      <div :style="{height: 12 + 'px', background: 'rgb(119, 221, 119)', width: (sentiment.sentiment_avg * 100) + '%', borderRadius: 25 + 'px'}" v-if="sentiment.label === 'POS'"></div>
                      <div :style="{height: 12 + 'px', background: '#ff4d4d', width: (sentiment.sentiment_avg * 100) + '%', borderRadius: 25 + 'px'}" v-if="sentiment.label === 'NEG'"></div>
                      <div :style="{height: 12 + 'px', background: 'rgb(230, 230, 230)', width: (sentiment.sentiment_avg * 100) + '%', borderRadius: 25 + 'px'}" v-if="sentiment.label === 'NEU'"></div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="center-content" style="padding-top:7px; padding-bottom: 7px;">
                  <div class="card-section-subtitle" style="color: #F7ECE1; font-size: 1.2rem; background-color: #251101; width:96%; border-radius: 6px; padding-top:7px; padding-bottom: 7px;">Creator personality</div>
            </div>
            <div class="center-content">
                  <p class="card-section-subtitle subtitle-box">Personality traits</p>
            </div>
            <div>
              <div class="metrics-sub-container">
                <table class="custom-table">
                  <tr>
                    <td class="col-80"></td>
                    <td class="col-20">Intensity</td>
                  </tr>
                  <tr v-for="(value, emotion) in personalityIndex" :key="emotion">
                    <td class="col-80">{{ capitalizeFirstLetter(emotion) }}</td>
                    <td class="col-20">
                      <div class="gauge-container">
                          <div class="gauge-personality" :style="{width: formatPersonalityIndex(value) + '%', backgroundColor: '#77dd77'}"></div>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
              <div style="padding:10px 18px 0px 18px; font-size: 0.75rem; text-align: justify;">Content creator's personality traits highlight the balance and intensity of each in shaping their creative output, content.</div>
            </div>
            <div class="center-content" style="padding-top: 20px;">
                  <p class="card-section-subtitle subtitle-box">🏆 Unique trait</p>
            </div>
            <div class="metrics-container">
                <div class="metrics-sub-container" style="padding-bottom: 0px;">
                  <div class="metrics-inner-div" style="font-weight: bold;font-size: 1.1rem; padding-top:0px;">{{ capitalizeFirstLetter(streamer.UNIQUE_TRAIT) }}</div>
                </div>
            </div>

            <div class="center-content" style="padding-top: 20px;" v-if="!indexesLoading">
                  <p class="card-section-subtitle subtitle-box">Emotional intensity</p>
            </div>
            <div v-if="!indexesLoading">
              
              <div class="center-content">
                <div class="gauge-container">
                          <div class="gauge" :style="{width: emotionalIndex + '%', backgroundColor: '#77dd77'}"></div>
                          <div class="industry-average" :style="{left: emotionalIndexTwitchAvg + '%'}"></div>
                </div>
              </div>
              <div style="padding:10px 18px 5px 18px; font-size: 0.95rem;" v-if="formatInteger(emotionalIndex) > emotionalIndexTwitchAvg">
                Emotional Intensity Score: <span style="font-weight: bold;">
                {{ formatInteger(emotionalIndex) }}</span>  
                <span style="font-size: 0.7rem;"> [{{ Math.floor((emotionalIndex / 48 - 1) * 100) }}% above <font-awesome-icon :icon="['fab', 'twitch']" class="twitch-icon"/> average]</span>
              </div>
              <div style="padding:10px 18px 5px 18px; font-size: 0.95rem;" v-else-if="formatInteger(emotionalIndex) < emotionalIndexTwitchAvg">
                Emotional Intensity Score: <span style="font-weight: bold;">
                  {{ formatInteger(emotionalIndex) }}</span>  
                  <span style="font-size: 0.7rem;"> [{{ Math.floor((emotionalIndex / 48 - 1) * 100) }}% below <font-awesome-icon :icon="['fab', 'twitch']" class="twitch-icon"/> average]</span>
                </div>
              <div style="padding:10px 18px 5px 18px; font-size: 0.95rem;" v-else>
                Emotional Intensity Score: <span style="font-weight: bold;" >
                  {{ formatInteger(emotionalIndex) }}</span> 
                  <span style="font-size: 0.7rem;"> [on par with <font-awesome-icon :icon="['fab', 'twitch']" class="twitch-icon"/> average]</span>
                </div>
              <div style="padding:2px 18px 5px 18px; font-size: 0.75rem; text-align: justify;">Measures emotional expression in content, scaling from neutral <span style="font-style: italic;">(0)</span> to very emotional <span style="font-style: italic;">(100)</span>, illustrating emotional richness.</div>

            </div>
          </div>
        </div>

      </div>
    </div>
  </template>
  
<script>
// import { BASE_URL } from "@/config.js"
import axios from "axios"

export default {
  props: ['streamer'],
  data () {
    return {
      isTooltipCreatorPersonalityVisible: false,
      isTooltipContentSafetyVisible: false,

      emotionalIndexTwitchAvg: 48,

      categoriesLoading: true,
      indexesLoading: true,
      categories_thumbnails: {},
      emotionalIndex: null,
      personalityIndex: null,
    }
  },
  async created() {
    this.fetchCategories(this.streamer.TOP5_GAMES);
    this.fetchIndexes(this.streamer.user_id);
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    visitChannel(channelName) {
      const url = `https://www.twitch.tv/${channelName}`;
      window.open(url, '_blank', 'noopener noreferrer');
    },
    formatNumber(number) {
        try {
          return number.toLocaleString('en-US');
        } catch (e) {
          return number;
        }
      },
      formatDecimal(number) {
        try {
          return Math.round(number * 10) / 10;
        } catch (e) {
          return number;
        }
      },
      formatInteger(number) {
        try {
          return Math.floor(number);
        } catch (e) {
          return number;
        }
      },
      capitalizeFirstLetter(string) {
        if (!string) return string; // Return the original string if it's empty or not a string
        return string.charAt(0).toUpperCase() + string.slice(1);
      },
      formatTime(totalSeconds) {
          const hours = Math.floor(totalSeconds / 3600);
          const minutes = Math.floor((totalSeconds % 3600) / 60);

          return `${hours}h ${minutes}min`;
        },
      findPercentileForValue(data, value, key) {
          const percentiles = data[key];

          if (!percentiles) {
            throw new Error(`Key ${key} not found in data`);
          }
          let foundPercentile = null;

          for (const [percentile, threshold] of Object.entries(percentiles)) {
            const thresholdValue = parseFloat(threshold);

            if (value <= thresholdValue) {
              foundPercentile = percentile;
              break;
            }
          }
          if (foundPercentile === null) {
            foundPercentile = '100';
          }

          return foundPercentile;
        },
        getPercentileClass(value, metric_id) {
          const percentile = this.findPercentileForValue(this.$store.state.twitchPercentileBenchmarks, value, metric_id);
          if (percentile <= 30) {
            return 'high-percentile';
          } else if (percentile <= 70) {
            return 'mid-percentile';
          } else {
            return 'low-percentile';
          }
        },
        async fetchCategories(data) {
          try {
            const response = await axios.post('/api/category_thumbnails', data);
            this.categories_thumbnails = response.data;
          } catch (error) {
            console.error(error);
          } finally {
            this.categoriesLoading = false;
          }
        },
        async fetchEmotionalIndex(userId) {
          try {
            const response = await axios.post('/api/emotional_index', { userId: userId });
            this.emotionalIndex = 100 - (response.data * 100);
          } catch (error) {
            console.error(error);
          }
        },
        async fetchPersonalityIndex(userId) {
          try {
            const response = await axios.post('/api/personality_index', { userId: userId, pers1: this.streamer.TOP1_TRAIT, pers2: this.streamer.TOP2_TRAIT, pers3: this.streamer.TOP3_TRAIT, pers4: this.streamer.TOP4_TRAIT, pers5: this.streamer.TOP5_TRAIT });
            this.personalityIndex = response.data;
          } catch (error) {
            console.error(error);
          }
        },
        async fetchIndexes(userId) {
          try {
            await this.fetchEmotionalIndex(userId);
            await this.fetchPersonalityIndex(userId);
          } catch (error) {
            console.error(error);
          } finally {
            this.indexesLoading = false;
          }
        },
        isGameInResults(game) {
          const gameInfo = this.categories_thumbnails[game];
          return gameInfo && gameInfo.twitch_id && gameInfo.twitch_id > 1;
        },
        getThumbnailImg(gameName) {
          const twitch_id = this.categories_thumbnails[gameName].twitch_id
          let url = ''
          if (this.categories_thumbnails[gameName].is_igdb) {
            url = "https://static-cdn.jtvnw.net/ttv-boxart/" + twitch_id + "_IGDB-68x95.jpg" // "-136x190.jpg"
          }
          else {
            url = "https://static-cdn.jtvnw.net/ttv-boxart/" + twitch_id + "-68x95.jpg"
          }
          return url
        },
      sumAllValues(obj) {
        return Object.values(obj).reduce((acc, val) => acc + parseFloat(val), 0);
      },
      formatPersonalityIndex(value) {
        let formattedIndex = this.formatInteger (parseFloat(value) / this.sumAllValues(this.$data.personalityIndex) * 100 ) * 2
        if (formattedIndex > 100) {
          formattedIndex = 100
        }
        return formattedIndex
      }
      },
};
</script>
  
<style scoped>

.industry-average {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 3px; 
    background-color: rgb(37, 17, 1); 
  }

.container {
  display: flex;
  align-items: center; /* This aligns the items vertically in the center */
  gap: 10px; /* This adds some space between the elements */
}
.center-align {
  text-align: center;
}
.twitch-icon {
  color: #251101; /* Twitch's brand purple color */
}
.tooltip-icon {
  color: #42b983;
  font-size: 1.2rem;
  cursor: pointer;
}
/* Style the modal and its content */

  
.modal-content {
  margin: 1%;
  background: white;
  padding: 15px;
  border-radius: 5px;
  position: relative;
}

.close-button-container {
  margin-left: 1%;
  width: 20%;
}

.close-button-container button {
  background-color: #ff4d4d;
  color: white;
  padding: 13px 30px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 50%;
}
.close-button-container button:hover {
  background-color: #e60000; /* Change the background color on hover */
  color: white; /* Change the text color on hover */
}
/* header formatting */
.streamer-profile-icon {
  width: 55px;
  height: 55px;
  border-radius: 50%;
}

.open-channel-link {
  font-size: 1rem;
  text-decoration: none; /* Removes the underline by default */
  color:#251101; /* Twitch's brand purple color */
  background-color: #F7ECE1;  /* 9E7B9B  9146FF*/
  padding: 3px 10px;
  border: none;
  border-radius: 5px;
}

.open-channel-link:hover {
  text-decoration: underline; /* Adds underline on hover */
}

/* metric cards */
.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.card {
  flex: 1;
  margin: 0.3%;
  box-sizing: border-box;
  border: solid thin;
  border-radius: 10px;
  border-color: rgb(240, 240, 240);
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 10px 5px 15px 5px;
}

.metrics-container {
    display: flex;
    /* justify-content: space-around; */
}

.metrics-sub-container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center align items horizontally */
    justify-content: center; /* Center align items vertically */
    flex:1;
}

.metrics-inner-div {
    font-size: 0.75rem;
    padding: 8px;
    text-align: center;
}
.center-content {
  display: flex;
  justify-content: center; 
  align-items: center; 
  width: 100%;
}
.subtitle-box {
  border-top: 1px solid #42b983;
  width: 93%;
  padding: 5px;
  margin: 8px 0 8px 0;
}
.card-section-subtitle{
  font-size: 1rem;
  font-weight: bold;
  color: #42b983;
  text-align: center;
}
/* KPI TABLE RELATED FORMATTING */  
.custom-table {
  width: 96%;
  border-collapse: collapse; /* Ensures there are no gaps between cells */
  font-size: 1rem;
}

.custom-table td {
  border: none; /* Hides all borders */
  vertical-align: middle;
}

.custom-table .col-80 {
  width: 80%;
  font-size: 0.95rem;
}

.custom-table .col-20 {
  width: 20%;
  font-size: 0.85rem;
  text-align: center;
}


.game-value {
  font-size: 0.8rem;
  padding: 5px;
  margin: 1px;
  border: 1px solid #ccc;
  border-radius: 5px;
  border-color: #42b983;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; /* Optional, adds ellipsis for overflow text */
}

.divider {
  border-top: 1.5px solid #42b983; /* Green horizontal line */
  margin: 0 0 5px 0;
}
.subtitle {
  margin: 5px 0 3px 0;
  font-size: 1.2rem;
  font-weight: bold;
  color: #42b983;
}
.sentiment-formatting{
  font-size: 0.8rem;
  margin-bottom: 10px;
}

.tooltip-content {
  max-width: 90%;
  text-align: justify;
  text-align-last: left;
  word-wrap: break-word;
  padding-bottom: 15px;
  font-size: 0.85rem;
}

/* percentile formatting */
.percentile-badge {
  padding: 3px 8px;
  margin-left: 10px;
  border-radius: 12px;
  color: white;
  font-weight: bold;
  display: inline-block;
}

.low-percentile {
  background-color: #ff6961; /* Red for lower percentiles */
}

.mid-percentile {
  background-color: #d0d076; /* Yellow for mid-range percentiles */
}

.high-percentile {
  background-color: #77dd77; /* Green for higher percentiles */
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  font-size: 0.85rem;
  visibility: hidden;
  width: 220px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.metric-definitions-button {
  font-size: 1rem; 
  padding: 3px 10px; 
  background-color: #F7ECE1; 
  color: #251101; 
  border: none; 
  border-radius: 5px;
  text-decoration: none;
}

.metric-definitions-button:hover {
  text-decoration: underline;
  cursor: pointer;
}

.gauge-container {
  width: 95%;
  background-color: #eee;
  border-radius: 5px;
  margin-top: 5px;
  position: relative;
}

.gauge {
  height: 13px;
  border-radius: 5px;
  background-color: #42b983;
}
.gauge-personality {
  height: 8px;
  border-radius: 5px;
  background-color: #42b983;
}

@media screen and (max-width: 970px) {

.card-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
}
}
</style>