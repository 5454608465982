<template>
    <div class="centered-container filter-height">
        <div class="content-wrapper">

            <div class="title">Choose what games they stream</div>
            <div class="brand-hint">Click on rows in the table to add categories to your search criteria <font-awesome-icon class="sbits-green" icon="fa-square-check"/></div>

            <div v-if="loading" class="loading-message">Loading categories...</div>
            
            <div v-if="!loading && filteredTwitchCategories.length" class="row-flex-container">
                <button 
                    class="pagination-btn" 
                    v-bind:style="{ display: !showPreviousViewButton ? 'none' : 'block' }" 
                    :disabled="!showPreviousViewButton"
                    @click="changeTableView(-1)">
                    <font-awesome-icon class="nav-icon" icon="fa-chevron-left"/>
                </button>
                <table>
                    <thead>
                        <tr class="header-row">
                            <th class="header-row" style="width:20%; text-align: center;">
                                <button @click="toggleSelectedCategories" class="toggle-categories-btn" v-if="this.$store.state.filterState.selectedTwitchCategories.length > 0">
                                    <span v-if="!showOnlySelectedCategories">Show selected</span>
                                    <span v-else>Show all</span>
                                </button>
                            </th>
                            <th class="header-row" style="width:30%; text-align: left;">
                                Category name
                            </th>
                            <th class="header-row" style="width:30%; text-align: left;"></th>
                            <th class="header-row" style="width:20%; text-align: center;">Rank (stream time)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="category-row" v-for="category in categoriesInTableView" :key="category" 
                            @click="selectTwitchCategory(category[0])"
                            :class="{ 'selected': this.$store.state.filterState.selectedTwitchCategories.includes(category[0]) }">
                            <td class="category-row" style="text-align: center;">
                                <span v-if="this.$store.state.filterState.selectedTwitchCategories.includes(category[0])"><font-awesome-icon class="sbits-green" icon="fa-square-check"/></span>
                                <span v-else></span>
                            </td>    
                            <td class="category-row">{{ category[0] }}</td>
                            <td class="category-row"> <span class="content-group" :style="{ backgroundColor: groupBackgroundColor(category[1].group) }">{{ formattedContentGroup(category[1].group) }}</span> </td>
                            <td class="category-row" style="text-align: center;">{{ category[1].rank }}</td>
                        </tr>

                    </tbody>
                </table>
                <button 
                    class="pagination-btn" 
                    v-bind:style="{ display: !showNextViewButton ? 'none' : 'block' }" 
                    :disabled="!showNextViewButton"
                    @click="changeTableView(1)">
                    <font-awesome-icon class="nav-icon" icon="fa-chevron-right"/>
                </button>
            </div>
            <div v-if="!loading" class="display-view-status">
                <span>Page {{ this.currentTableView + 1 }}/{{ Math.ceil(this.filteredTwitchCategories.length/this.rowsPerTableView) }}</span>
            </div>

        </div>
    </div>
</template>

<script>
// import gameContentTags from '@/data/game_tags.json'
// import { BASE_URL } from "@/config.js"
import axios from "axios"

export default {
    name: "TwitchCategoriesFilter",
    emits: ['update-twitch-categories'],
    data() {
        return {
            loading: false,

            twitchCategories: {}, // all received categories from API
            filteredTwitchCategories: [], // all categories converted to 
            categoriesInTableView: [],

            currentTableView: 0,
            rowsPerTableView: 6,
            showPreviousViewButton: false,
            showNextViewButton: true,
            showOnlySelectedCategories: false,

            // content category group formatting
            contentGroupBackgrounds: {
                gameplayExperience: '#F6F1D1', 
                gameGenre: '#CFD7C7',
                gameContent: '#70A9A1',
                gamePresentation: '#40798C',
                default: '#896A67'
            }
        }
    },
    mounted() {
        this.getTwitchCategories();
    },
    methods: {
        async getTwitchCategories() {
            this.loading = true;
            //const response = await axios.get(BASE_URL + 'api/content_category_ranks');
            const response = await axios.get('/api/content_category_ranks');
            const searchData = response.data;
            this.twitchCategories = searchData
            this.filteredTwitchCategories = Object.entries(searchData);
            this.getTableView();
            this.loading = false;
        },
        selectTwitchCategory(item) {
            let categories = this.$store.state.filterState.selectedTwitchCategories
            if (categories.includes(item)) {
                categories = categories.filter(selectedItem => selectedItem !== item);
            } else {
                categories.push(item);
            }
            this.$store.commit('updateSelectedTwitchCategories', categories)
        },

        // table page navigation viewIncrement
        getTableView() {
            let sliceUpper = 0;
            let sliceLower = 0;
            let categories = [];

            if (this.currentTableView < 1) {
                categories = this.filteredTwitchCategories.slice(0, this.rowsPerTableView)
            }
            else {
                sliceUpper = this.rowsPerTableView * (this.currentTableView + 1);
                sliceLower = (this.rowsPerTableView * this.currentTableView);
                categories = this.filteredTwitchCategories.slice(sliceLower, sliceUpper)
            }

            this.categoriesInTableView = categories;
            
        },
        changeTableView(viewIncrement) {
            let newView = this.currentTableView + viewIncrement
            if (newView > 0) {
                this.currentTableView = newView
            } else {
                this.currentTableView = 0
            }
  
            this.getTableView()

            // handle button state
            if (viewIncrement > 0) {
                this.showPreviousViewButton = true
            }
            if (viewIncrement < 0 || (viewIncrement===0 && this.filteredTwitchCategories.length > this.rowsPerTableView )) {
                this.showNextViewButton = true
            }
            if (this.categoriesInTableView.length < this.rowsPerTableView || this.filteredTwitchCategories.length === (this.rowsPerTableView * (this.currentTableView + 1))){
                    this.showNextViewButton = false
                }
            if (this.currentTableView === 0) {
                this.showPreviousViewButton = false
            }
        },

        //
        toggleSelectedCategories() {

            this.showOnlySelectedCategories = !this.showOnlySelectedCategories;

            if (this.showOnlySelectedCategories === true) {
                
                let object = this.twitchCategories;
                const filteredObject = {};

                this.$store.state.filterState.selectedTwitchCategories.forEach(key => {
                    // Check if the current array element is a key in the object
                    if (Object.prototype.hasOwnProperty.call(object, key)) {
                        filteredObject[key] = object[key];
                    }
                });

                this.currentTableView = 0;
                this.filteredTwitchCategories = Object.entries(filteredObject);
                this.changeTableView(0);
            }
            else {
                this.filteredTwitchCategories = Object.entries(this.twitchCategories);
                this.changeTableView(0);
            }
        },

        // formatting 
        formattedContentGroup(group) {
            if (group === 'gameplayExperience') {
                return 'Gameplay Experience'
            }
            else if (group === 'gameGenre') {
                return 'Game Genre'
            }
            else if (group === 'gameContent') {
                return 'Gameplay Theme'
            }
            else if (group === 'gamePresentation') {
                return 'Features & Presentation'
            }
            else {
                return 'Non-gaming Content'
            }
        },
        groupBackgroundColor(group) {
            return this.contentGroupBackgrounds[group] || this.contentGroupBackgrounds.default;
        }
    }
}

</script>

<style scoped>
@import '@/assets/styles/filterViews.css';

/* New */ 
.centered-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 500px;
  /* height: 60vh; */
}
.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Aligns items to the start (left) */
  width: 85%;
}
.row-flex-container {
    display: flex;
    flex-direction: row;
    gap: 10px; 
    font-size: 1.1rem;
    height: 100%;
    width: 100%;
    align-items: center;     /* Center vertically */
}
.sbits-green {
    color: #42b983;
}
.display-view-status {
    font-size: 0.8rem;
    padding-top: 10px;
}
/* table styles */
table {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.85rem;
  border-collapse: collapse;
}
.category-row, .header-row {
    border: 1px solid #ddd;
    padding: 6px 2px 6px 2px;
    border-left: none;
    border-right: none; 
}

.category-row:hover {
    background-color: #EFEFEF;
    cursor: pointer;
} 

/* Text formatting */
.title {
  font-size: 1rem;
  font-weight: bold;
  font-size: 25px;
  margin-bottom: 5px;
}
.status-text {
  font-size: 1rem;
  font-weight: bold;
}
.brand-hint {
    font-size: 0.9rem;
    padding-bottom: 20px;
}

.loading-message {
    padding-top: 20px; 
}

/* chip formatting */ 
.custom-input {
    display: none; 
}
.custom-label {
    display: inline-block;
    padding: 2px 8px 2px 8px;
    background-color: #EFEFEF;
    border: 1px solid #CCC;
    border-radius: 5px;
    display: flex; 
    justify-content: center;
    align-items: center; 
    text-align: center;
    font-size: 0.9rem;
    height: 20px;
}
.custom-label:hover {
    background-color: #DDD;
}

/* content group formatting */
.content-group {
    background-color: #42b983;
    border-radius: 10px;
    padding: 2px 6px 2px 6px;
}

/* navigation */
.pagination-btn {
    background-color: rgb(19, 75, 95);
    color: white;
    height: 100%;
    border: none;
    width: 6%;
}
.pagination-btn:hover {
    background-color: rgb(107, 156, 174);
    cursor: pointer;
}

.nav-icon {
    font-size: 1.2rem;
}

/* buttons */
.toggle-categories-btn {
    color: #2c3e50;
    font-size: 0.85rem;
    padding: 2px 6px 2px 6px;
    background-color: #EFEFEF;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    width: 90%;
}

.toggle-categories-btn:hover {
    background-color: rgb(220, 220, 220);
}
</style>