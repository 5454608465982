<template>
    <div class="center-div-container">
        <div style="text-align: center; font-size: 40px; font-weight: bold;">Start Your Search</div>
    </div>
    <div class="centered">
        <button class="explore-btn" @click="changeToBrandGPTFilterComponent">Set filters <i class="tooltip-icon"><font-awesome-icon icon="fas fa-sliders-h"/></i></button>
        <div>or</div>
        <button class="explore-btn" @click="changeToChannelSearchComponent">Find a channel <i class="tooltip-icon"><font-awesome-icon icon="fa-keyboard"/></i></button>
    </div>
    <div class="noti">Your search is in limited mode (access to only a few creator profiles). Partner with Storylime to get full access.</div>
</template>

<script>
export default {
    name: "DefaultFilterScreen",
    emits: ['change-active-filter-component'],
    data() {
        return {
            activeFilterComponent: 'BrandGPT'
        }
    },
    methods: {
        changeToBrandGPTFilterComponent() {
            this.$emit('change-active-filter-component', 'BrandGPT')
            this.$store.commit('changeDefaultFilterScreenVisibility', false)
        },
        changeToChannelSearchComponent() {
            this.$emit('change-active-filter-component', 'SearchByChannel')
            this.$store.commit('changeDefaultFilterScreenVisibility', false)
        }
    }
}
</script>

<style scoped>
.center-div-container {
  justify-content: center;
  align-items: center;
  padding-top: 90px;
  padding-bottom: 50px;
  display: flex;
  font-family: 'Roboto', sans-serif;
}
.centered {
  justify-content: center;
  align-items: center;
  margin: auto;
  display: flex;
  padding-bottom: 30px;
}
.explore-btn {
  margin: 0 30px 0 30px;
  width: 12rem;
  height: 3.5rem;
  font-size: 1rem;
  text-align: center;
  color: white;
  background-color: rgb(19, 75, 95);
  border: 1px solid #ccc;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease; 
}
.explore-btn:hover {
  /* box-shadow: inset 15.5rem 0 0 0 black; */
	background-color:  #42b983;
}

.noti {
  padding: 10px 30px 10px 30px;
  color: #FF4D4D;
  font-weight: bold;
  margin: 1% 1% 1% 1%;
  border: solid 0.5px;
  border-radius: 5px;
  border-color: #FF4D4D;
  background-color: white;
  text-align: center;
  font-size: 1.1rem;
}
</style>


<!-- Your journey of discovering the universe of creator personalities and content types starts here -->