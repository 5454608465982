<template>
        <div v-if="showBrandGPTInputView" class="centered-container filter-height">
            <div class="content-wrapper">

                <div v-if="!personalitiesAreLoading" class="title">Describe what defines your brand or campaign</div>
                <div v-if="!personalitiesAreLoading" class="brand-hint template-width">
                    Are you innovative, sustainable, or luxurious? Consider your tone: friendly and conversational or formal and authoritative? Detail these unique traits to help us match you with creators who reflect your brand's identity and values. 
                    <a v-if="personalitySearchInput === ''" href="#" @click="toggleBrandExample">{{ this.toggleLinkText }}</a>
                </div>

                <div v-if="!personalitiesAreLoading" class="template-width">
                    <textarea 
                        class="personality-input template-width" 
                        type="text" 
                        maxlength="500" 
                        @focus="expandInput"
                        @blur="shrinkInput"
                        v-model="personalitySearchInput" 
                        :placeholder="brandExample"></textarea> 
                </div>

                <div class="warning-text" v-if="isInputExceeded">You have exceeded the maximum character limit</div>
                
                <div v-if="personalitiesAreLoading" class="loading-personalities template-width">
                    <div class="spinner"></div>
                    <div class="loading-message">Getting suggestions...</div>
                </div>

                <div v-if="!personalitiesAreLoading" class="btn-container">
                    <button 
                        v-if="!isInputExceeded"
                        class="personality-search-btn" 
                        :class="{ 'btn-disabled': personalitySearchInput === '' }" 
                        @click="findMatchingPersonalities" 
                        :disabled="personalitySearchInput === ''">
                        <span v-if="this.$store.state.filterState.suggestedPersonalities.length === 0">Get suggestions</span>
                        <span v-else>Get new suggestions</span>
                    </button>
                    <button 
                        v-if="this.$store.state.filterState.suggestedPersonalities.length !== 0"
                        class="return-to-search-btn"
                        @click="toggleBrandGPTInputView" 
                        >
                        Back to current suggestions
                        <font-awesome-icon icon="fa-solid fa-circle-chevron-left"/>
                    </button>
                </div>
            </div>
        </div>

        <div v-else class="centered-container filter-height">
            <div class="content-wrapper">
                <div class="personality-container template-width">
                    <div class="title">Mark the traits you want as your search filters</div>
                    <div class="brand-hint">Here is a selection of streamer personalities that may resonate with your style</div>
                    
                    <div class="personality-container">
                        <label class="chip" v-for="personality in this.$store.state.filterState.suggestedPersonalities" :key="personality" 
                            @change="selectPersonality(personality)"
                            :class="{ 'selected': this.$store.state.filterState.selectedPersonalities.includes(personality) }">
                            <input class="custom-input" type="checkbox" :value="personality" :checked="this.$store.state.filterState.selectedPersonalities.includes(personality)">
                            {{ personality }}
                        </label>
                    </div>

                    <div class="btn-container">
                        <button class="select-all-btn s-top-margin" :class="{'all-selected': allSelected}" @click="toggleSelectAll">
                            {{ this.toggleSelectAllDisplayMessage }}
                            <i v-if="this.toggleSelectAllDisplayMessage === 'Select all personalities'" class="tooltip-icon"><font-awesome-icon class="sbits-green" icon="fa-check-circle"/></i>
                            <i v-else class="tooltip-icon"><font-awesome-icon class="sbits-red" icon="fa-times-circle"/></i>
                        </button>

                        <button 
                            class="return-to-search-btn s-top-margin" 
                            @click="toggleBrandGPTInputView" 
                            >
                            Write a new description
                            <font-awesome-icon icon="fa-solid fa-wand-magic-sparkles"/>
                        </button>
                    </div>
                </div>
            </div>
        </div>

</template>


<script>
// import { BASE_URL } from "@/config.js"
import axios from "axios"

export default {
    name: "BrandGPT",
    data() {
        return {
            // API data structures
            filter_personalityList: [],
            allowed_personalities: [],
            mockTraits: ["Adventurous", "Detail-Oriented", "Low Self-Esteem", "Inquisitive", "Talkative", "Strategic", "Knowledgeable", "Confident", "Engaging"],
            toggleSelectAllDisplayMessage: 'Select all personalities',

            // brand prompt input formatting
            personalitySearchInput: '',
            brandExample: 'Start describing your brand to get suggestions...',
            toggleLinkText: 'Show example.',
            showBrandExample: false,
            isInputExpanded: false,
            baseHeight: 40,
            scaleFactor: 1,

            // ChatGPT loading screens
            personalitiesAreLoading: false,

            // Navigation within BrandGPT
            showBrandGPTInputView: true,
        }
    },
    methods: {
        // Component's core functionality
        async findMatchingPersonalities () {
            this.personalitiesAreLoading = true;
            //const response = await axios.post(BASE_URL + 'api/matching_personalities', {text: this.personalitySearchInput});
            const response = await axios.post('/api/matching_personalities', {text: this.personalitySearchInput});
            const personality_traits = response.data.result;
            this.allowed_personalities = personality_traits;
            // this.allowed_personalities = this.mockTraits;
            // this.filter_personalityList = Object.values(this.allowed_personalities);
            this.$store.commit('updateSuggestedPersonalities', Object.values(this.allowed_personalities))
            this.personalitiesAreLoading = false;
            this.showBrandGPTInputView = false;
        },
        selectPersonality(item) {
            let personalities = this.$store.state.filterState.selectedPersonalities
            if (personalities.includes(item)) {
                personalities = personalities.filter(selectedItem => selectedItem !== item);
            } else {
                personalities.push(item);
            }
            this.$store.commit('updateSelectedPersonalities', personalities)
        },
        toggleSelectAll() {
            let personalities = [];
            if (this.filter_personalityList.length === this.$store.state.filterState.selectedPersonalities.length) {
                // All items are selected, so clear the selection
                this.$store.commit('updateSelectedPersonalities', personalities)
                this.toggleSelectAllDisplayMessage = 'Select all personalities';
            } else {
                // Not all items are selected, so select all
                personalities = [...this.filter_personalityList];
                this.$store.commit('updateSelectedPersonalities', personalities)
                this.toggleSelectAllDisplayMessage = 'Clear all personalities';
            }
        },
        // Input interactivity functions
        toggleBrandExample() {
            this.showBrandExample = !this.showBrandExample;
            if (this.showBrandExample === true) {
                this.brandExample = "Our brand stands for sustainable luxury, combining innovation with eco-friendly practices. Catering to those who prioritize quality and environmental care, we offer premium, responsible products with a professional yet warm approach, emphasizing detail and positive impact."
                this.toggleLinkText = 'Hide example.'
                this.expandInput()
            } else {
                this.brandExample = 'Start describing your brand to get suggestions...'
                this.toggleLinkText = 'Show example.'
                this.shrinkInput()
            }
        },
        toggleBrandGPTInputView() {
            this.showBrandGPTInputView = !this.showBrandGPTInputView;
        },
        expandInput() {
            this.isInputExpanded = true;
        },
        shrinkInput() {
            if (this.personalitySearchInput === '' && this.brandExample.length <= 100) {
                this.isInputExpanded = false;
            }
        },
        hideBrandGPTInput() {
            if (this.$store.state.filterState.suggestedPersonalities.length !== 0) {
                this.showBrandGPTInputView = false;
            }
        }
    },
    computed: {
        allSelected() {
            return this.filter_personalityList.length === this.$store.state.filterState.selectedPersonalities.length;
        },
        isInputExceeded() {
            return this.personalitySearchInput.length === 500; 
        },
    },
    mounted() {
        this.hideBrandGPTInput();
    }
}
</script>

<style scoped>
@import '@/assets/styles/filterViews.css';

/* LAYOUT */
.s-top-margin {
    margin-top: 12px;
}
.template-width {
    width: 100%
}
.sbits-green {
    color: #42b983;
}
.sbits-red {
    color: #FF4D4D;
}
.centered-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Aligns items to the start (left) */
  width: 80%
}
.btn-container {
    display: flex;
    flex-direction: row;
    gap: 3%;
    width: 100%;
}
.loading-personalities {
  display: flex;
  justify-content: center; /* Aligns children (spinner) horizontally center */
  align-items: center; /* Aligns children (spinner) vertically center */
  flex-direction: column; /* Stacks children vertically */
  min-height: 150px;
}
.personality-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; 
  font-size: 1.1rem;
  width: 100%;
}
/* STREAMER PERSONALITIES */
.select-all-btn {
    color: #2c3e50;
    font-size: 16px;
    padding: 8px 20px;
    background-color: #EFEFEF;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    width: 50%;
}

.return-to-search-btn {
    color: #2c3e50;
    font-size: 16px;
    padding: 8px 20px;
    background-color: #EFEFEF;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    width: 50%;
}

.select-all-btn:hover {
    background-color: rgb(220, 220, 220);
}
.return-to-search-btn:hover {
    background-color: rgb(220, 220, 220);
}

/* LOADING MESSAGE FORMATTING */
.spinner {
  border: 4px solid rgba(0,0,0,0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #42b983;
  animation: spin 1s ease infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading-message {
  margin-top: 10px;
  font-size: 1.2em;
  color: #2c3e50;
  font-family: Avenir, Helvetica, Arial, sans-serif;
}

/* personality input formatting */
.personality-input {
    box-sizing: border-box;
    min-height: 20px;
    height: 140px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    outline: none;
    font-size: 0.9rem;
    transition: border-color 0.2s;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    margin-bottom: 10px;
}
.personality-input::placeholder {
  color: #888;
}
.personality-input:focus {
  border-color: #42b983;
}
.personality-input-expanded {
    min-height: 140px;
}
.personality-search-btn {
  background-color: #42b983;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 20px;
  cursor: pointer;
  flex: 1;
  font-size: 16px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  width: 50%;
  height: 40px;
}
.personality-search-btn:not(.btn-disabled):hover {
  background-color: #359c63;
}
.btn-disabled {
  background-color: grey;
  color: rgba(190, 190, 190);
  cursor: default;
  border: none;
  padding: 8px 20px;
  font-size: 16px;
  flex: 1;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  width: 50%;
}

/* text formatting - legacy */
.title {
  font-weight: bold;
  font-size: 25px;
  padding-bottom: 10px;
}
.brand-hint {
    font-size: 0.9rem;
    padding-bottom: 15px;
}
.warning-text {
    color: #ff0000; 
    font-size: 14px; 
    font-weight: bold; 
    padding: 8px 20px; 
    border: 1px solid #ff0000; 
    background-color: #ffe5e5; 
    border-radius: 5px; 
    text-align: center; 
}
/* chip formatting */ 
.custom-input {
    display: none; 
}
.chip {
    display: inline-block;
    padding: 5px;
    background-color: white;
    border: 1px solid #CCC;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.9rem;
}
.chip.selected {
    background-color: #42b983;
    border: 1px solid #42b983;
    color: #fff;
}
.chip.selected:hover {
    background-color: #42b983;
    color: #fff;
}
.chip:hover {
    border: 1px solid #42b983;
}
</style>