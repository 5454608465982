<template>
  <div class="main-filter-div">
    <div>

      <StreamerFilter 
        v-if="!isModalVisible" 
        :currentPage="currentPage"
        @update-streamers="handleUpdateStreamers"
        @update-current-page="handleUpdateCurrentPage" 
        ref="streamerFilter"
      />

      <div v-if="!cardsAreLoading && Object.keys(this.$data.streamers).length===0" class="narrow-search-warning center-align">No matches found. Expand your search criteria to explore a wider range of streamers.</div>
      <div v-if="!cardsAreLoading && this.$data.streamers[0]===404" class="center-align">No matches found.</div>

      <div v-if="!isModalVisible" class="card-container">
        <div v-for="streamer in streamers" v-bind:key="streamer.id" class="card"> 
          <div >
            <div class="flex-container centered adjust-padding">
              <img v-bind:src="streamer.CHANNEL_ICON_URL" class="streamer-profile-icon"/>
              <p class="subtitle">{{ streamer.CHANNEL_NAME }}</p>
            </div>
          </div>
          <div>
            <div class="centered card-metric-padding">
              <div>
                <div class="center-content">
                  <p class="card-section-subtitle subtitle-box">Engagement</p>
                </div>
                <div class="metrics-container">
                    <div class="metrics-sub-container">
                        <div class="metrics-inner-div" style="font-weight: bold; font-size: 1.1rem;">{{ formatNumber(streamer.TOTAL_FOLLOWERS) }}</div>
                        <div class="metrics-inner-div" style="padding: 0px;">Followers</div>
                    </div>
                    <div class="metrics-sub-container">
                        <div class="metrics-inner-div" style="font-weight: bold;font-size: 1.1rem;">{{ formatNumber(streamer.AVG_VIEWERS_90D) }}</div>
                        <div class="metrics-inner-div" style="padding: 0px;">Viewers</div>
                    </div>
                    <div class="metrics-sub-container">
                        <div class="metrics-inner-div" style="font-weight: bold;font-size: 1.1rem;">{{ formatDecimal(streamer.STREAMS_PER_WEEK_90D) }}</div>
                        <div class="metrics-inner-div" style="padding: 0px;">Weekly streams</div>
                    </div>
                </div>

                <div class="center-content">
                  <p class="card-section-subtitle subtitle-box">Creator personality</p>
                </div>
                <div class="metrics-container">
                    <div class="metrics-sub-container">
                        <div class="metrics-inner-div" style="font-weight: bold; font-size: 1.1rem;">{{ capitalizeFirstLetter(streamer.TOP1_TRAIT) }}</div>
                        <div class="metrics-inner-div" style="padding: 0px;">Common trait</div>
                    </div>
                </div>
                <div class="metrics-container">
                    <div class="metrics-sub-container">
                        <div class="metrics-inner-div" style="font-weight: bold;font-size: 1.1rem;">{{ capitalizeFirstLetter(streamer.UNIQUE_TRAIT) }}</div>
                        <div class="metrics-inner-div" style="padding: 0px;">Unique trait</div>
                    </div>
                </div>
                <div class="metrics-container">
                    <div class="metrics-sub-container" style="padding-bottom: 0px;">
                        <div class="metrics-inner-div" style="font-weight: bold;font-size: 1.1rem;" :class="getPercentileClass(streamer.OBSCENITY_PER_HOUR, '4')">{{findPercentileForValue(this.$store.state.twitchPercentileBenchmarks, streamer.OBSCENITY_PER_HOUR,'4') }}</div>
                        <div class="metrics-inner-div">🤬 Profanity level</div>
                    </div>
                    <div class="metrics-sub-container" style="padding-bottom: 0px;">
                        <div class="metrics-inner-div" style="font-weight: bold;font-size: 1.1rem;" :class="getPercentileClass(streamer.TOXICITY_PER_HOUR, '5')">{{findPercentileForValue(this.$store.state.twitchPercentileBenchmarks, streamer.TOXICITY_PER_HOUR,'5') }}</div>
                        <div class="metrics-inner-div">☢️ Toxicity level</div>
                    </div>
                </div>

              </div>
            </div>
          </div>
          <div class="card-button-container">
            <button class="streamer-card-button" @click="showStreamerDetails(streamer)">Expand details</button>
          </div>
        </div>
      </div>
      

      <StreamerDetailModuleExpand 
        v-if="isModalVisible" 
        :streamer="selectedStreamer" 
        @close="closeModal" 
      />

      <!-- PAGINATION -->
      <div class="streamer-table-pagination" v-if="Object.keys(this.$data.streamers).length>=0 && !isModalVisible" style="padding-bottom: 50px;">
        <div id="streamer-pagination-buttons">
          <button v-if="currentPage>1" class="hover" id="pagination-button" @click="callSearch(changePage(currentPage - 1))"><span><font-awesome-icon :icon="['fas', 'arrow-left']" class="fa-xs"/></span></button>
          <button v-if="Object.keys(this.$data.streamers).length>1" class="pagination-current-page">{{ currentPage }}</button>
          <button v-if="Object.keys(this.$data.streamers).length>=this.$data.resultsPerPage" class="hover" id="pagination-button" @click="callSearch(changePage(currentPage + 1))"><span><font-awesome-icon :icon="['fas', 'arrow-right']" class="fa-xs"/></span></button>
        </div>
      </div>

    </div>
  </div>
  <div class="mobile-message">
    <div style="font-size: 1.3rem; padding: 90px 30px 20px 30px; text-align: justify; line-height: 2.4rem;">Sorry, search is currently unavailable on smartphones 📱</div>
    <div style="font-size: 1.3rem; padding: 20px 30px 20px 30px; text-align: justify; line-height: 2.4rem;">To access search, please switch to a laptop or tablet 💻</div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
// import StreamerDetailModule from '@/components/StreamerDetailModule.vue'; StreamerDetailModule
import StreamerFilter from '@/components/StreamerFilter.vue';
import axios from "axios"

import StreamerDetailModuleExpand from '@/components/StreamerDetailModuleExpand.vue';

export default {
    name: "StreamerView_new",
    components: { StreamerFilter, StreamerDetailModuleExpand },
    data() {
        return {
            streamers: {},
            currentPage: 1,
            numberFormattingOptions: { locale: 'en-US' },
            resultsPerPage: 9,
            isModalVisible: false,
            selectedStreamer: null,
            cardsAreLoading: false,
        };
    },
    methods: {
        callSearch(page) {
            this.$refs.streamerFilter.search(page); 
        },
        async showStreamerDetails(streamer) {
          if (Object.keys(this.$store.state.twitchPercentileBenchmarks).length === 0) {
            const response = await axios.get('/api/metric_percentiles')
            const percentileData = response.data;
            this.$store.commit('addPercentileData', percentileData);
          }
          this.selectedStreamer = streamer;
          this.isModalVisible = true;
        },
        async getPercentileBenchmarks() {
          if (Object.keys(this.$store.state.twitchPercentileBenchmarks).length === 0) {
            const response = await axios.get('/api/metric_percentiles')
            const percentileData = response.data;
            this.$store.commit('addPercentileData', percentileData);
          }
        },
        closeModal() {
          this.selectedStreamer = null;
          this.isModalVisible = false;
        },
        handleUpdateStreamers(newStreamers) {
            this.streamers = newStreamers;
        },
        handleUpdateCurrentPage(newPage) {
          this.currentPage = newPage; // Update the currentPage with the emitted value
        },
        changePage(newPage) {
          this.currentPage = newPage;
          return newPage
        },
        formatNumber(number) {
          try {
            return number.toLocaleString('en-US');
          } catch (e) {
            return number;
          }
        },
        formatDecimal(number) {
          try {
            return Math.round(number * 10) / 10;
          } catch (e) {
            return number;
          }
        },
        capitalizeFirstLetter(string) {
          if (!string) return string; // Return the original string if it's empty or not a string
          return string.charAt(0).toUpperCase() + string.slice(1);
        },

        // relative metric formatters
        findPercentileForValue(data, value, key) {
            const percentiles = data[key];

            if (!percentiles) {
              throw new Error(`Key ${key} not found in data`);
            }
            let foundPercentile = null;

            for (const [percentile, threshold] of Object.entries(percentiles)) {
              const thresholdValue = parseFloat(threshold);

              if (value <= thresholdValue) {
                foundPercentile = percentile;
                break;
              }
            }
            if (foundPercentile === null) {
              foundPercentile = '100';
            }

            return foundPercentile;
          },
        getPercentileClass(value, metric_id) {
          const percentile = this.findPercentileForValue(this.$store.state.twitchPercentileBenchmarks, value, metric_id);
          if (percentile <= 30) {
            return 'high-percentile';
          } else if (percentile <= 70) {
            return 'mid-percentile';
          } else {
            return 'low-percentile';
          }
        }
    },
    async mounted() {
        try {
            this.cardsAreLoading = true;
            const url = '/api/streamer_data?page=1'
            const response = await axios.get(url);
            this.$data.streamers = response.data;
            this.getPercentileBenchmarks().catch(error => {
              console.error("Error in getPercentileBenchmarks:", error);
            });
            this.cardsAreLoading = false;
        }
        catch (error) {
            console.log(error);
        }
    }
}
/* eslint-enable no-unused-vars */
</script>

<style scoped>
.centered {
  justify-content: center;
  align-items: center;
  margin: auto;
}
.center-content {
  display: flex;
  justify-content: center; 
  align-items: center; 
  height: 100%;
  width: 100%;
}
.adjust-padding {
  padding: 15px 0 3px 0;
}
.card-metric-padding {
  padding: 0 0 15px 0;
}

.center-align {
  text-align: center;
}
.flex-container {
  display: flex;
  column-gap: 1.5rem;
}
.subtitle {
  font-size: 1.5rem;
  font-weight: bold;
}
/* INTRODUCTION AND KEY DIV FORMATTING */
.main-filter-div {
  margin: 20px;
}


/* TOO NARROW SEARCH WARNING */
.narrow-search-warning {
  padding: 30px;
  color: #FF4D4D;
  font-weight: bold;
  margin: 1% 1% 1% 1%;
  border: solid thin;
  border-radius: 5px;
  border-color: #FF4D4D;
  background-color: white;
}

/* PAGINATION */
.streamer-table-pagination {
  display: block;
  padding: 10px;
}
.hover:hover{
  background-color:  #42b983 !important;
}
#streamer-pagination-buttons{
  align-items: right;
}

/* STREAMER CARDS */
.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.card-container:after {
  content: "";
  flex: 1;
}
.card {
  flex: 0 0 calc(33.33333% - 2%); /* Adjust as needed for 3 cards per row */
  margin: 1%;
  box-sizing: border-box;
  border: solid thin;
  border-radius: 10px;
  border-color: rgb(247, 247, 247);
  background-color: white;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.card:hover {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); 
}
.streamer-profile-icon {
  width: 55px;
  height: 55px;
  border-radius: 50%;
}
.card-button-container {
  margin-top: auto;
}
.streamer-card-button {
  width: 100%;
  height: 2.5rem;
  font-size: 1rem;
  text-align: center;
  color: #251101;
  font-weight: bold;
  background-color: #F7ECE1; /* 251101 9FE2BF */
  border: none;
  cursor: pointer;
  font-family: 'Roboto', sans-serif; 
  text-decoration: none;
}
.streamer-card-button:hover {
  background-color: #ded4ca; /* 251101 9FE2BF */
}
.subtitle-box {
  border-top: 1px solid #42b983;
  width: 93%;
  padding: 5px;
  margin: 8px 0 8px 0;
}
.card-section-subtitle{
  font-size: 1rem;
  font-weight: bold;
  color: #42b983;
  text-align: center;
}

.low-percentile {
  background-color: #ff6961; /* Red for lower percentiles */
  padding: 3px 8px;
  margin-left: 10px;
  border-radius: 12px;
  color: white;
  font-weight: bold;
  display: inline-block;
}

.mid-percentile {
  background-color: #d0d076; /* Yellow for mid-range percentiles */
  padding: 3px 8px;
  margin-left: 10px;
  border-radius: 12px;
  color: white;
  font-weight: bold;
  display: inline-block;
}

.high-percentile {
  background-color: #77dd77; /* Green for higher percentiles */
  padding: 3px 8px;
  margin-left: 10px;
  border-radius: 12px;
  color: white;
  font-weight: bold;
  display: inline-block;
}

/* redesigned engagement metrics */
.metrics-container {
    display: flex;
    justify-content: space-around;
}

.metrics-sub-container {
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center align items horizontally */
    justify-content: center; /* Center align items vertically */
    flex:1;
}

.metrics-inner-div {
    font-size: 0.75rem;
    padding: 8px;
    text-align: center;
}

.mobile-message {
  display: none;
}

@media screen and (max-width: 950px) {
  .card {
    flex: 0 0 calc(50% - 2%); /* Adjust as needed for 3 cards per row */
    margin: 1%;
    box-sizing: border-box;
    border: solid thin;
    border-radius: 10px;
    border-color: rgb(247, 247, 247);
    background-color: white;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
@media screen and (max-width: 650px) {
  .card {
    flex: 0 0 calc(100% - 2%); /* Adjust as needed for 3 cards per row */
    margin: 1%;
    box-sizing: border-box;
    border: solid thin;
    border-radius: 10px;
    border-color: rgb(247, 247, 247);
    background-color: white;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

@media screen and (max-width: 599px) {

  .main-filter-div {
    display: none
  }

  .mobile-message {
    display: block;
  }
}

</style>
