<template>
  <div class="flex-container">
    <!-- Navigation Bar -->
    <div class="sideBar" v-if="isFullNavigationBar">
        <button id="navigation-btn" @click="hideNavigationText" role="link"><font-awesome-icon icon="fa-solid fa-bars" class="navigation-icon" /></button>
        <router-link to="/" custom v-slot="{ navigate }">
          <button id="navigation-btn" @click="navigate" role="link"><font-awesome-icon icon="fa-solid fa-house" class="navigation-icon" /><span class="navigation-description">Home</span></button> 
        </router-link>
        <router-link to="/streamers" custom v-slot="{ navigate }">
          <button id="navigation-btn" @click="navigate" role="link"><font-awesome-icon icon="fa-solid fa-magnifying-glass" class="navigation-icon" /><span class="navigation-description">Find creators</span></button>
        </router-link>
        <router-link to="/campaign" custom v-slot="{ navigate }">
          <button id="navigation-btn" @click="navigate" role="link"><font-awesome-icon icon="fa-solid fa-wand-magic-sparkles" class="navigation-icon" /><span class="navigation-description">Plan campaign</span></button>
        </router-link>
    </div>
    <!-- Narrow navigation Bar --> 
    <div class="sideBar" v-if="!isFullNavigationBar">
        <button id="navigation-btn" @click="hideNavigationText" role="link"><font-awesome-icon icon="fa-solid fa-bars"/></button>
        <router-link to="/" custom v-slot="{ navigate }">
          <button id="navigation-btn" @click="navigate" role="link"><font-awesome-icon icon="fa-solid fa-house"/></button> 
        </router-link>
        <router-link to="/streamers" custom v-slot="{ navigate }">
          <button id="navigation-btn" @click="navigate" role="link"><font-awesome-icon icon="fa-solid fa-magnifying-glass"/></button>
        </router-link>
        <router-link to="/campaign" custom v-slot="{ navigate }">
          <button id="navigation-btn" @click="navigate" role="link"><font-awesome-icon icon="fa-solid fa-wand-magic-sparkles"/></button>
        </router-link>
    </div>

    <!-- Route Content -->
    <div id="route">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>

export default {
  name: 'MainView',
  data() {
    return {
      isFullNavigationBar: true
    };
  },
  methods: {
    hideNavigationText() {
      this.isFullNavigationBar = !this.isFullNavigationBar;
    }
  }
};
</script>


<style scoped>
@import '../src/assets/styles/default.css';
@import '../src/assets/styles/buttons.css';


.navigation-icon {
  margin-right:10px;
}

#navigation-btn:hover {
	background-color: rgb(107, 156, 174); 
}

/* navigation sidebar to the right */
/* .flex-container {
  display: flex;
} */

/* .sideBar {
  background-color: rgb(19, 75, 95);
  display: inline-block;
  min-height: 100vh; 
} */
.sideBar {
    width: 100%; /* Take the full width on mobile view */
    display: flex;
  }

#route {
  flex: 1; 
  color: #2c3e50;
  background-color: rgb(247, 247, 247);
}

#navigation-btn {
    border: none;
    color: white;
    text-align: center;
    padding: 16px;
    cursor: pointer;
    display: inline-block; /*display: flex; */
    position: relative;
    transition: 0.4s;
    transition: all 0.25s cubic-bezier(0.42, 0, 0.58, 1);
    background-color: rgb(19, 75, 95);
    width: 100%;
    font-size: 1rem;
  }


/* #77e9b6c1  #F7ECE1 rgb(107, 156, 174)*/

/* Add media query to apply styles for mobile view */
@media (max-width: 1028px) {
  .flex-container {
    flex-direction: column;
  }

  .sideBar {
    width: 100%; /* Take the full width on mobile view */
    display: flex;
  }

  #route {
    width: 100%; /* Take the full width on mobile view */
  }

  #navigation-btn {
    border: none;
    color: white;
    text-align: center;
    padding: 16px;
    cursor: pointer;
    display: inline-block;
    transition: 0.4s;
    transition: all 0.25s cubic-bezier(0.42, 0, 0.58, 1);
    background-color: rgb(19, 75, 95);
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 600px) {
  .navigation-description {
    display: none;
  }
}
</style>
