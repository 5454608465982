<template>
  <div style="margin: 20px; display: flex; justify-content: center; align-items: center; height: 80vh;">
    <div>
      <div style="font-size: 2.5rem;">Coming soon ⏰</div>
      <div style="padding-top: 30px; font-size: 1rem;">
        <li>Campaign benchmarks & metrics</li>
      </div>
      <div style="padding-top: 15px; font-size: 1rem;">
        <li>Audience overlap detection</li>
      </div>
      <div style="padding-top: 15px; font-size: 1rem;">
        <li>Creator contacts</li>
      </div>
      <div style="padding-top: 15px; font-size: 1rem;">
        <li>Presonalized campaign suggestions</li>
      </div>
    </div>
  </div>
</template>

<script>
export default { name: 'CampaignView' }
</script>
