<template>
    <div class="streamer-filter-div">

        <div class="filter-component">
            <div class="filter-view-div filter-height">
                
                <DefaultFilterScreen 
                    v-if="this.$store.state.showDefaultFilterScreen"
                    @change-active-filter-component="handleComponentSwitchFromDefault"/>
                
                <SearchByChannelName 
                    v-if="activeFilterComponent === 'SearchByChannel'"
                    @search-channel-by-name="newSearch"
                    @change-active-component="showBrandGPT"
                    />

                <BrandGPT 
                    v-if="activeFilterComponent === 'BrandGPT'"
                    />

                <TwitchCategoriesFilter 
                    v-if="activeFilterComponent === 'TwitchCategoriesFilter'" 
                    /> 
                
                <ChannelCharacteristicsFilter
                    v-if="activeFilterComponent === 'ChannelCharacteristicsFilter'"
                    />
            </div>

            <div v-if="!this.$store.state.showDefaultFilterScreen" class="filter-status-div">
                <StreamerFilterSelectionStatus/>
                <div class="noti">Your search is in limited mode (access to only a few creator profiles). Partner with Storylime to get full access.</div>
            </div>
        </div>

        <div v-if="!this.$store.state.showDefaultFilterScreen" class="filter-component">
            <div class="filter-view-div">
                <div style="display: flex; gap: 0;">
                    <button 
                        class="default-navigation-button"
                        :class="{ 'selected-btn': selectedButton === 'BrandGPT' }"
                        @click="showBrandGPT">BrandGPT <font-awesome-icon icon="fa-solid fa-wand-magic-sparkles"/></button>
                    <button 
                        class="default-navigation-button"
                        :class="{ 'selected-btn': selectedButton === 'TwitchCategoriesFilter' }" 
                        @click="showTwitchCategoriesFilter">Content categories <font-awesome-icon icon="fa-solid fa-gamepad"/></button>
                    <button 
                        class="default-navigation-button"
                        :class="{ 'selected-btn': selectedButton === 'ChannelCharacteristicsFilter' }" 
                        @click="showChannelCharacteristicsFilter">Channel details <font-awesome-icon icon="fa-solid fa-magnifying-glass-chart"/></button>
                    <button 
                        class="default-navigation-button"
                        :class="{ 'selected-btn': selectedButton === 'SearchByChannel' }"  
                        @click="showChannelSearch">Channel name <font-awesome-icon icon="fa-solid fa-magnifying-glass"/></button>
                </div>
            </div>
            <div style="flex: 1">
                <div style="display: flex; gap: 0;">
                    <button @click="newSearch" class="streamer-search-button">Search</button>
                    <button @click="resetFilters" class="streamer-search-reset-button">Reset filters</button>
                </div>
            </div>
        </div>

    </div>
    
    <div v-if="cardsAreLoading" class="analyzing-container">
          <div class="spinner"></div>
          <p class="analyzing-text">Searching content creators...</p>
    </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import StreamerFilterSelectionStatus from '@/components/StreamerFilterSelectionStatus.vue';
import BrandGPT from '@/components/filters/BrandGPT.vue'; 
import TwitchCategoriesFilter from '@/components/filters/TwitchCategoriesFilter.vue';
import DefaultFilterScreen from '@/components/filters/DefaultFilterScreen.vue';
import SearchByChannelName from '@/components/filters/SearchByChannelName.vue';
import ChannelCharacteristicsFilter from '@/components/filters/ChannelCharacteristicsFilter.vue';
// import { BASE_URL } from "@/config.js"
import axios from "axios"

export default {
    name: "StreamerFilter",
    components: { StreamerFilterSelectionStatus, BrandGPT, TwitchCategoriesFilter, DefaultFilterScreen, SearchByChannelName, ChannelCharacteristicsFilter },
    props: { currentPage: Number },
    emits: ['update-streamers', 'update-current-page'],
    data() {
        return {
            activeFilterComponent: null,
            selectedButton: null,
            cardsAreLoading: false,

            streamers: {},
            previousParams: {},
            numberFormattingOptions: { locale: 'en-US' },
        };
    },
    methods: {
        // visualisaitons
        showBrandGPT() {
            let name = 'BrandGPT'
            this.activeFilterComponent = name;
            this.selectButton(name)
        },
        showTwitchCategoriesFilter() {
            let name = 'TwitchCategoriesFilter';
            this.activeFilterComponent = name;
            this.selectButton(name)
        },
        showChannelCharacteristicsFilter() {
            let name = 'ChannelCharacteristicsFilter';
            this.activeFilterComponent = name;
            this.selectButton(name)
        },
        showChannelSearch() {
            let name = 'SearchByChannel';
            this.activeFilterComponent = name;
            this.selectButton(name)
        },
        selectButton(buttonName) {
            this.selectedButton = buttonName;
        },
        // emitters
        updateStreamers(newStreamers) {
            this.streamers = newStreamers;
            this.$emit('update-streamers', newStreamers);
        },
        // receive incoming emits
        handleComponentSwitchFromDefault(newComponent) {
            this.activeFilterComponent = newComponent;
            this.selectButton(newComponent)
        },

        // compontent specific functionality
        async search(page) {
          try {
            this.cardsAreLoading = true;

            let queryParams = {
              channelName: this.$store.state.filterState.filter_channel_name,
              page: page,

              minViews: this.$store.state.filterState.minViews,
              maxViews: this.$store.state.filterState.maxViews,
              minFollowers: this.$store.state.filterState.minFollowers,
              maxFollowers: this.$store.state.filterState.maxFollowers,

              obscenity: this.$store.state.filterState.obscenityLevel,
              toxicity: this.$store.state.filterState.toxicityLevel,

              contentCategories: this.$store.state.filterState.selectedTwitchCategories,
              personalities: this.$store.state.filterState.selectedPersonalities
            };


            if (Array.isArray(this.filter_selectedEmotions) && this.filter_selectedEmotions.length > 0) {
              queryParams.selectedEmotions = this.filter_selectedEmotions.map(emotion => emotion.slice(0, -2).trim().toLowerCase()).join(','); 
            }
            if (this.$store.state.filterState.selectedTwitchCategories.length > 0) {
              queryParams.contentCategories = this.$store.state.filterState.selectedTwitchCategories.map(category => category.trim().toLowerCase()).join(','); 
            }
            if (this.$store.state.filterState.selectedPersonalities.length > 0) {
              queryParams.personalities = this.$store.state.filterState.selectedPersonalities.map(category => category.trim().toLowerCase()).join(','); 
            }

            const filteredParams = Object.fromEntries(
              Object.entries(queryParams).filter(([_, value]) => value)
            );
            // const response = await axios.get(BASE_URL + 'api/streamer_data', {
            //   params: filteredParams,
            // });
            const response = await axios.get('/api/streamer_data', {
              params: filteredParams,
            });
            const searchData = response.data;
            this.streamers = searchData;
            this.cardsAreLoading = false;
            this.updateStreamers(this.streamers)
          } catch (error) {
            console.error(error);
          }
        },
        resetFilters() {
            let currentPage = 1;
            this.$emit('update-current-page', currentPage);

            this.$store.commit('SET_FILTER_CHANNEL_NAME', '');

            const followerData = {
                    minFollowers: '',
                    maxFollowers: '',
                };
            this.$store.commit('updateFollowerFilter', followerData);

            const viewerData = {
                minViews: '',
                maxViews: '',
            };
            this.$store.commit('updateViewerFilter', viewerData);

            this.$store.commit('updateToxicityLevel', 100);
            this.$store.commit('updateObscenityLevel', 100);

            this.$store.commit('updateSelectedPersonalities', [])
            this.$store.commit('updateSelectedTwitchCategories', [])

            this.search(1);
        },
        newSearch() {
          let currentPage = 1;
          this.$emit('update-current-page', currentPage);
          this.search(1);
        },
    },
    created() {
        if (!this.$store.state.showDefaultFilterScreen) {
            this.activeFilterComponent = "SearchByChannel";
        }
    },
}
/* eslint-enable no-unused-vars */
</script>

<style scoped>
@import '@/assets/styles/filterViews.css';

.streamer-filter-div {
  margin: 1% 1% 1% 1%;
  padding: 10px;
  border-radius: 5px;
  background-color: white;
  flex-direction: column;
  display: flex;
}

.filter-view-div {
    flex: 2;
}

.filter-status-div {
  flex: 1;
}

.filter-component {
  display: flex;
  column-gap: 1.5rem;
}

/* FILTERING */
.streamer-search-button {
  background-color: #42b983;
  color: white;
  border: none;
  height: 40px;
  padding: 5px 10px;
  font-size: 14px;
  cursor: pointer;
  flex: 2;
}
.streamer-search-button:hover {
  background-color: #359c63;
}
.streamer-search-reset-button {
  background-color: #FF9494; 
  color: white;
  border: none;
  padding: 5px 10px;
  font-size: 14px;
  cursor: pointer;
  flex: 1;
}
.streamer-search-reset-button:hover {
  background-color: #FF4D4D;
}

/* Filter navigation */
.default-navigation-button {
  background-color: #EFEFEF;
  height: 40px;
  color: rgb(19, 75, 95);
  border: none;
  padding: 5px 10px;
  font-size: 14px;
  cursor: pointer;
  flex: 1;
}
.selected-btn {
	background-color:  rgb(19, 75, 95);
    color: white;
}
.default-navigation-button:hover {
	/* background-color:  rgb(200, 200, 200); */
    background-color: rgb(220, 220, 220);
    color: rgb(19, 75, 95);
}

/* LOADING SCREEN */
.analyzing-container {
  display: flex;
  justify-content: center; /* Aligns children (spinner) horizontally center */
  align-items: center; /* Aligns children (spinner) vertically center */
  padding: 20px;
  flex-direction: column; /* Stacks children vertically */
}

.spinner {
  border: 4px solid rgba(0,0,0,0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #09f;
  animation: spin 1s ease infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.analyzing-text {
  margin-top: 10px;
  font-size: 1.2em;
  color: #2c3e50;
}

.noti {
  padding: 0px 0px 10px 0px;
  color: #FF4D4D;
  font-weight: bold;
  margin: 1% 1% 1% 1%;
  background-color: white;
  font-size: 0.8rem;
  text-align: center;
}

@media screen and (max-width: 870px) {
  .filter-component {
    flex-direction: column; 
    display: flex;
  }

  .filter-status-div {
    display: none;
  }
}

</style>
