<template>
    <div class="centered-container filter-height">
        <div class="content-wrapper">
            <div class="title" style="white-space: nowrap;">Applied filters</div>
            
            <table v-if="this.$store.state.filterState.filter_channel_name !== ''">
                <tbody>
                    <tr>
                        <td>Channel name</td>
                        <td class="status-column">
                            <font-awesome-icon class="sbits-green" icon="fa-square-check"/>
                        </td>
                    </tr>                    
                </tbody>
            </table>
            <table v-else>

                <tbody>
                    <!-- selected chip filters -->
                    <tr v-if="this.$store.state.filterState.selectedPersonalities.length !== 0">
                        <td>BrandGPT (<font-awesome-icon class="sbits-green" icon="fa-check"/> {{ this.$store.state.filterState.selectedPersonalities.length }})</td>
                        <td class="status-column">
                            <font-awesome-icon class="sbits-green" icon="fa-square-check"/>
                        </td>
                    </tr>

                    <tr v-if="this.$store.state.filterState.selectedTwitchCategories.length !== 0">
                        <td>Content categories (<font-awesome-icon class="sbits-green" icon="fa-check"/> {{ this.$store.state.filterState.selectedTwitchCategories.length }})</td>
                        <td class="status-column">
                            <font-awesome-icon class="sbits-green" icon="fa-square-check"/>
                        </td>
                    </tr>

                    <!-- range filters -->
                    <tr v-if="this.$store.state.filterState.minFollowers !== '' || this.$store.state.filterState.maxFollowers !== ''">
                        <td>Follower count</td>
                        <td class="status-column">
                            <font-awesome-icon class="sbits-green" icon="fa-square-check"/>
                        </td>
                    </tr>

                    <tr v-if="this.$store.state.filterState.minViews !== '' || this.$store.state.filterState.maxViews !== ''">
                        <td>Viewer count</td>
                        <td class="status-column">
                            <font-awesome-icon class="sbits-green" icon="fa-square-check"/>
                        </td>
                    </tr>

                    <!-- percentile filters -->
                    <tr>
                        <td>Obscenity: {{ getFormattedTier(this.$store.state.filterState.obscenityLevel) }}</td>
                        <td><font-awesome-icon class="sbits-green" icon="fa-square-check"/></td>
                    </tr>

                    <tr>
                        <td>Toxicity: {{ getFormattedTier(this.$store.state.filterState.toxicityLevel) }}</td>
                        <td><font-awesome-icon class="sbits-green" icon="fa-square-check"/></td>
                    </tr>

                </tbody>
            </table>

        </div>
    </div>
</template>

<script>
export default {
    name: "StreamerFilterSelectionStatus",
    methods: {
        getFormattedTier(percentile) {
            let formattedPercentile = ''
            const score = percentile / 10 
            if (percentile === 0) {
                formattedPercentile = 'minimal (1/10)'
            }
            else if (percentile < 30) {
                formattedPercentile = 'minimal (' + score + '/10)'
            }
            else if (percentile < 60) {
                formattedPercentile = 'moderate (' + score + '/10)'
            }
            else if (percentile < 80) {
                formattedPercentile = 'high (' + score + '/10)'
            }
            else if (percentile < 100) {
                formattedPercentile = 'very high (' + score + '/10)'
            }
            else {
                formattedPercentile = 'unrestricted'
            }
            return formattedPercentile
        }
    }
    }
</script>

<style scoped>
@import '@/assets/styles/filterViews.css';
.centered-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Aligns items to the start (left) */
  width: 90%
}
.status-column {
    text-align: center;
    width: 5%;
}
.title {
  font-weight: bold;
  font-size: 25px;
  margin-bottom: 20px;
}

.sbits-green {
    color: #42b983;
}
/* table styles */
table {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.85rem;
  border-collapse: collapse;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
  border-left: none;
  border-right: none;
}

/* th {
  background-color: #f2f2f2;
} */

/* tr:nth-child(even) {
  background-color: #f9f9f9;
} */
</style>