<template>
  <div class="main-div">
  
    <div class="description">
      <div class="header-active">
        <h1 class="main-header-leg" style="padding-top: 35px; font-family: 'Roboto', sans-serif;">Pair your brand with creators</h1>
        <h1 class="main-header-leg" style="font-family: 'Roboto', sans-serif;">who match its values</h1>
        <h1 class="main-header-leg" style="font-family: 'Roboto', sans-serif;">and communication style</h1>
        <div class="sub-header-leg">Discover Twitch <span><font-awesome-icon :icon="['fab', 'twitch']" class="twitch-icon"/></span> streamers by brand risk level, content tone, and more!</div>
      </div>
    </div>

    <div class="description"  style="padding-bottom: 100px; padding-top: 100px;">
        <div class="service-presentation left-border">
          <div style="flex: 1.5;">
              <div class="service-description">
                <div><img class="logo-img" :src="logo" style="padding-left: 0px;"></div>
                <div style="font-size: 1.1rem; line-height: 2rem; padding-bottom: 20px; padding-left: 15px;">Storylime - an <span>AI solution</span> for brands to <span>effortlessly and with confidence find creators</span> who align with your brand identity and campaign goals.</div>
                <div style="font-size: 1.1rem; padding-left: 15px;">🚀 AI-Powered Insights & Search</div>
                <div style="font-size: 1.1rem; padding-left: 15px;">☑️ Brand Safety & Risk</div>
                <div style="font-size: 1.1rem; padding-left: 15px;">☑️ Content Mood, Tone & Style</div>
                <div style="font-size: 1.1rem; padding-left: 15px;">☑️ Creator Personality & Character</div>
              </div>
          </div>
        
          <div class="header-container-leg" style="flex: 2">
              <div class="footer-button">
                <router-link to="/streamers">
                  <button @mouseenter="changeImage" @mouseleave="resetImage">Search creators</button>
                </router-link>
              </div>
              <img class="title-img" :src="tileImage" alt="Title Image" :class="{ 'title-img-bw': !isMouseOverTitle }">
          </div>
        </div>
      </div>


    <div class="description" style="padding-bottom: 20px; padding-top: 20px;">
      <div class="left-border description-app">
          <div style="padding:20px 0px 30px 25px; color: black; font-weight: bold; font-size: 1.9rem;">Get to <span style="font-style: italic; color:#42b983">really</span> know your content partners</div>
          <div class="container center-content">
            <div class="feature-explanation"  @click="selectFeature(1)" v-if="currentFeature === 1"><span><font-awesome-icon :icon="['far', 'circle-dot']" class="feature-icon"/></span> Describe Your Brand <span><font-awesome-icon :icon="['fas', 'arrow-right-long']" class="feature-icon"/></span> Get Presonalized Creator Suggestions</div>
            <div class="feature-explanation-inactive"  @click="selectFeature(1)" v-else><span><font-awesome-icon :icon="['far', 'circle']"/></span> Describe Your Brand <span><font-awesome-icon :icon="['fas', 'arrow-right-long']"/></span> Get Presonalized Creator Suggestions</div>
          </div>

          <div class="container center-content">
            <div class="feature-explanation"  @click="selectFeature(2)" v-if="currentFeature === 2"><span><font-awesome-icon :icon="['far', 'circle-dot']" class="feature-icon"/></span> Seeking Profanity-Free Creators? <span><font-awesome-icon :icon="['fas', 'arrow-right-long']" class="feature-icon"/></span> Apply Robust Filters</div>
            <div class="feature-explanation-inactive" @click="selectFeature(2)" v-else><span><font-awesome-icon :icon="['far', 'circle']"/></span> Seeking Profanity-Free Creators? <span><font-awesome-icon :icon="['fas', 'arrow-right-long']"/></span> Apply Robust Filters</div>
          </div>

          <div class="container center-content">
            <div class="feature-explanation"  @click="selectFeature(3)" v-if="currentFeature === 3"><span><font-awesome-icon :icon="['far', 'circle-dot']" class="feature-icon"/></span> Advanced Insights <span><font-awesome-icon :icon="['fas', 'arrow-right-long']" class="feature-icon"/></span> Choose The Perfect Creator For You</div>
            <div class="feature-explanation-inactive"  @click="selectFeature(3)" v-else><span><font-awesome-icon :icon="['far', 'circle']"/></span> Advanced Insights <span><font-awesome-icon :icon="['fas', 'arrow-right-long']"/></span> Choose The Perfect Creator For You</div>
          </div>

          <div class="container center-content" v-if="currentFeature === 1">
            <img class="usability-image" :src="brandGPT_img" alt="Title Image">
          </div>
          <div class="container center-content" v-if="currentFeature === 2">
            <img class="usability-image" :src="filters_img" alt="Title Image">
          </div>
          <div class="container center-content" v-if="currentFeature === 3">
            <img class="usability-image" :src="streamer_details_img" alt="Title Image">
          </div>

      </div>
    </div>


    <div class="description"  style="padding-bottom: 100px; padding-top: 100px;">
        <div class="left-border description-app"> 

          <div style="padding:20px 0px 30px 25px; color: black; font-weight: bold; font-size: 1.9rem;">Build connections with live audiences <span style="font-style: italic; color:#42b983">confidently</span></div>

          <div style="font-size: 1.1rem; line-height: 2rem; padding: 0px 0px 20px 25px;">Finding the right content creator for your brand isn't just about numbers and reach; it's about alignment in character, personality, tone, and ethos.</div>

          <div style="font-size: 1.1rem; line-height: 2rem; padding: 0px 0px 20px 25px;">Our mission is to enhance how companies collaborate with live stream content creators.</div>

          <div style="display: flex; justify-content: space-around; padding-bottom: 20px;">
            <div class="activation-div">
              <div>🌟</div>
              <div>Your brand.</div>
            </div> 
            <div class="activation-div">
              <div>📣</div>
              <div>Their voice.</div>
            </div>
            <div class="activation-div">
              <div>🧘</div>
              <div>Perfect harmony.</div>
            </div> 
          </div>

        </div>
      </div>


    <div class="main-features-container">

      <div class="description"  style="padding-bottom: 100px; padding-top: 100px;">
        <div class="feature-presentation">
          <div style="flex: 2">
              <div class="feature-header mid-font">
                Tailored Suggestions at Scale
              </div>
              <div class="feature-header mid-font" style="color: #42b983; padding-bottom: 20px;">
                BrandGPT
              </div>
              <div class="feature-description-box-div left-border">
                Get tailor-made suggestions for creators who don't just speak to your target audience but speak like you.
              </div>
              <div class="feature-description-text min-font">
                <div style="line-height: 2rem; padding-top:10px;">
                  Your brand has a unique voice, and we're here to ensure it's heard. Our AI solution delves into the nuances of your brand's identity, campaign focus, and the creators that resonate with your values.
                </div>
              </div>
          </div>
        
          <div class="right-div center-content" style="flex: 1">
            <font-awesome-icon :icon="['fas', 'brain']" class="fa-4x" style="color: #42b983;"/>
          </div>
        </div>
      </div>

      <div class="description" style="padding-bottom: 100px; padding-top: 100px;">
        <div class="feature-presentation">
            <div style="flex: 2">
              <div class="feature-header mid-font">
                Going Beyond Audience Metrics
              </div>
              <div class="feature-header mid-font" style="color: #42b983; padding-bottom: 20px;">
                In-Depth Content Analytics 
              </div>
              <div class="feature-description-box-div left-border">
                Safeguard your brand's reputation with our innovative content risk and creator content analytics tools. 
              </div>
              <div class="feature-description-text min-font">
                <div style="line-height: 2rem; padding-top: 10px;">
                  We go beyond superficial audience metrics to provide a deep dive into streamer character traits, ensuring a match that's not just about numbers, but about personalities that truly reflect and enhance your brand's identity.
                </div>
              </div>
            </div>

          <div class="right-div center-content" style="flex: 1">
            <font-awesome-icon :icon="['fas', 'magnifying-glass-chart']" class="fa-4x" style="color: #42b983;"/>
          </div>
        </div>

      </div>

      <div class="description" style="padding-bottom: 100px; padding-top: 100px;">
        <div class="feature-presentation">
          <div style="flex: 2">

              <div class="feature-header mid-font">
                Custom Analysis & Projects
              </div>
              <div class="feature-header mid-font" style="color: #42b983; padding-bottom: 20px;">
                Collaborate With Us
              </div>
              <div class="feature-description-box-div left-border">
                Request custom analytics from our team of data scientists and analysts.
              </div>
              <div class="feature-description-text min-font">
                <div style="line-height: 2rem; padding-top: 10px;">
                  Let's explore your ideas together - detailed game overviews, streamer cluster analytics, deep dives into particular metrics like content risk scores or emotional tone, and more!
                </div>
              </div>
              <div class="feature-description-text">
                  <a href="mailto:uldis@hey.com?Subject=Project%20proposal" target="_top">
                    <button style="background-color: #4169E1;">Send us a message</button>
                  </a>
              </div>

          </div>

          <div class="right-div center-content" style="flex: 1">
            <font-awesome-icon :icon="['fas', 'code']" class="fa-4x" style="color: #42b983;"/>
          </div>
        </div>
      </div>

    </div>


    <div class="description">
        <div class="footer-active">
            <div class="feature-header" style="color: black;">
              Ready to amplify your brand's voice?
            </div>
            <div class="sub-header-leg" style="font-size: 1.1rem; line-height: 2rem; padding-bottom: 30px;">
              Bring your brand story to the forefront by collaborating with creators who truly grasp your vision and values.
            </div>
            <div class="footer-button" >
              <router-link to="/streamers">
                <button>Search creators now</button>
              </router-link>
            </div>
        </div>
    </div>

    <footer>
      <p>© 2024 Storylime AI. All rights reserved.</p>
    </footer>
  </div>
</template>

<script>
import title_img from '@/assets/streamer_crop_1000.png' 
import logo from '@/assets/storylime_logo.png'

// showcase functionality
import streamer_details from '@/assets/streamer_profile_img.png'
import brandGPT from '@/assets/brandGPT_img.png'
import filters_img from '@/assets/show_filters.png'

export default {
  name: 'HomeView',
  data() {
    return {
      logo: logo,

      // title images
      tileImage: title_img,
      isMouseOverTitle: false,

      // showcasing platform features
      streamer_details_img: streamer_details,
      brandGPT_img: brandGPT,
      filters_img: filters_img,
      currentFeature: 1,

      currentIndex: 0
    }
  },  
  methods: {
    changeImage() {
      this.isMouseOverTitle = true;
    },
    resetImage() {
      this.isMouseOverTitle = false;
    },
    selectFeature(featureNumber) {
      this.currentFeature = featureNumber;
    }
  },
  computed: {
      backgroundStyle() {
        return {
          backgroundImage: `url(${this.title_bw})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: '600px',
          width: '90%',
        };
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* title formatting sutff */
.header-container-leg {
  text-align: center;
  padding: 15px 15px 0px 15px;
  background-color: rgb(247, 247, 247); /* Soft grey background */
}

.main-header-leg {
  font-size: 2.1em;
  color: black; /*#333; */
}

.sub-header-leg {
  padding: 10px 0px 10px 0px;
  font-size: 1.2em;
  color: #666; 
  font-family: 'Open Sans', sans-serif;
}

.service-description {
  padding: 10px 40px 10px 20px;
  font-size: 1.2em;
  color: #666; 
  font-family: 'Open Sans', sans-serif;
  line-height: 3rem;
}

.main-div {
  font-family: 'Roboto', sans-serif; 
  min-height: 100vh;
}

/* usability formatting & explanations */
.usability-image {
  width: 100%;
}

button {
  background-color: #42b983;
  color: white;
  border: none;
  padding: 15px 25px;
  font-size: 1.4rem;
  cursor: pointer;
  border-radius: 10px;
  transition: background-color 0.3s ease;
  width: 100%
}

button:hover {
  background-color: #359c63;
}

footer {
  text-align: center;
  padding: 5px 0;
  font-size: 0.9rem;
}

.feature-presentation{
  display: flex;
  width: 100%;
  align-items: center; 
  justify-content: center; 
}

.service-presentation {
  display: flex;
  width: 100%;
  justify-content: center; 
}

.footer-active {
  /* width: 65%; */
  align-items: center; 
  justify-content: center; 
  width: 100%;
}
.header-active {
  /* width: 65%; */
  width: 100%;
  align-items: center; 
  justify-content: center; 
}
.footer-button {
  padding: 0px 0px 50px 0px; /* top, right, bottom, left */
}


.container {
  display: flex;
  align-items: center; 
}

.left-div, .right-div {
  flex: 1; 
  padding: 10px; 
  flex-basis: 50%;
}



.center-content {
  justify-content: center; 
  align-items: center; 
  display: flex;
}
.description {
    display: flex;
    justify-content: center; 
    align-items: center;
    width: 100%; 
    margin-top: 20px;
    margin-bottom: 20px;
}

.description > div {
    max-width: 1150px; 
}

.title-img{
  width: 100%; /* Ensure the image covers the container */
  height: auto;
  display: block; /* Removes bottom margin/padding */
}
.title-img-bw {
  z-index: 1;
  width: 100%; /* Ensure the image covers the container */
  height: auto;
  display: block; /* Removes bottom margin/padding */
  filter: grayscale(100%);
}
.logo-img{
  width: auto;
  max-height: 55px;
}

.right-border {
  border-left: 0; /* Adjust the color and width as needed */
  border-top: 0;
  border-right: 8px solid #42b983;
  border-bottom: 0;
  border-radius: 5px;
  border-color: #42b983;
}
.left-border {
  border-left: 8px solid #42b983; /* Adjust the color and width as needed */
  border-top: 0;
  border-right: 0;
  border-bottom: 0;
  border-radius: 5px;
  border-color: #42b983;
}

/* 3 word activations */
.activation-div {
    width: 30%; /* Each div takes up 20% of the outer div's width, totaling 60% */
    font-family: Avenir, Helvetica, Arial, sans-serif;
    font-weight: bold;
    text-align: center;
    font-size: 1.7rem;
    padding: 25px 0px 15px 0px; /* top, right, bottom, left */
}

/* features */
.main-features-container {
  /* padding: 40px; */
  width: 100%
}
.feature-header {
  font-size: 1.5em;
  color: black; 
  font-family: 'Roboto', sans-serif; 
  text-align: left;
  font-weight: bold;
  padding: 10px 10px 10px 0px;
}
.feature-description-text {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-size: 1.2rem;
  text-align: left;
  /* justify-content: center; */
  padding: 10px 10px 10px 0px;
}

.feature-description-box-div {
  padding: 7px 10px 7px 10px;
  background-color: white;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-size: 1rem;
  width: 90%;
  line-height: 2rem;
  text-align: left;
  justify-content: left;
}

.feature-explanation {
  color: #666; 
  font-family: 'Roboto', sans-serif; 
  font-weight: bold;
  border: none;
  padding: 13px 25px;
  font-size: 1.3rem;
  border-radius: 10px;
  width: 100%;
  cursor: pointer;
}

.feature-explanation-inactive:hover {
  color: rgb(66, 185, 131); 
  font-family: 'Roboto', sans-serif; 
  border: none;
  padding: 13px 25px;
  font-size: 1.3rem;
  border-radius: 10px;
  width: 100%;
  cursor: pointer;
}

.feature-explanation-inactive {
  color: #999; 
  font-family: 'Roboto', sans-serif; 
  border: none;
  padding: 13px 25px;
  font-size: 1.3rem;
  border-radius: 10px;
  width: 100%;
  cursor: pointer;
}

.feature-icon {
  color: #42b983;
}
.min-font {
  font-size: 1rem;
}
.mid-font {
  font-size: 1.7rem;
}

@media screen and (max-width: 1500px) {
  .service-presentation {
    display: flex;
    align-items: center; 
    width: 90%;
  }
  .feature-presentation {
    display: flex;
    width: 90%;
    align-items: center; 
    justify-content: center; 
  }
  .footer-active {
    width: 90%;
    align-items: center; 
    justify-content: center; 
  }
  .header-active {
    width: 90%;
    align-items: center; 
    justify-content: center; 
  }
  .description-app {
    width: 90%;
  }
}

@media screen and (max-width: 1028px) {
  .service-presentation {
    display: flex;
    flex-direction: column; 
    align-items: center; 
    width: 90%;
  }
  .feature-presentation {
    display: flex;
    flex-direction: column; 
    width: 90%;
    align-items: center; 
    justify-content: center; 
  }
  .footer-active {
    width: 90%;
    align-items: center; 
    justify-content: center; 
  }
  .header-active {
    width: 90%;
    align-items: center; 
    justify-content: center; 
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 550px) {
  .header-active {
    width: 90%;
    align-items: center; 
    justify-content: center; 
    font-size: 1rem;
  }
}
</style>
