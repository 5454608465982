<template>
    <div class="centered-container filter-height">
        <div class="content-wrapper">
            <div class="title" style="white-space: nowrap;">Search by channel name</div>
            <form @submit.prevent="triggerChannelSearch" style="width: 100%">
                <input 
                    v-model="filterChannelName" 
                    placeholder="Twitch channel name" 
                    class="search-box"
                    />
                <div class="btn-wrapper">
                    <button type="submit" class="search-btn">Search</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
export default {
    name: "SearchByChannelName",
    emits: ['search-channel-by-name', 'change-active-component'],
    data() {
        return {
            change_to_active_component: 'BrandGPT',
        }
    },
    methods: {
        triggerChannelSearch() {
            this.$emit('search-channel-by-name')
        },
        changeActiveComponent() {
            this.$emit('change-active-component', this.change_to_active_component)
        }
    },
    computed: {
        filterChannelName: {
            get() {
                return this.$store.state.filterState.filter_channel_name;
            },
            set(value) {
                this.$store.commit('SET_FILTER_CHANNEL_NAME', value);
            }
        }
    }
}
</script>

<style scoped>
@import '@/assets/styles/filterViews.css';
/* LAYOUT */
.centered-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Aligns items to the start (left) */
  width: 80%;
}
.btn-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start; /* Aligns items to the start (left) */
  gap: 20px;
}

/* Search box formatting */
.search-box {
    padding: 12px;
    margin: 0; /* Ensure no additional margin */
    border: 1px solid #ccc;
    border-radius: 8px;
    outline: none;
    font-size: 16px;
    transition: border-color 0.2s;
    height: 40px;
    width: 100%;
    font-family: Avenir, Helvetica, Arial, sans-serif;
}
.search-box::placeholder {
  color: #888;
}
.search-box:focus {
  border-color: #42b983;
}

/* BUTTON FORMATTING */ 
.search-btn {
  border: none;
  border-radius: 6px;
  height: 50px;
  width: 40%;
  padding: 8px 20px;
  font-size: 20px;
  cursor: pointer;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  margin-top: 20px;
}
.search-btn {
  background-color: #42b983;
  color: white;
}
.search-btn:hover {
  background-color: #359c63;
}

/* OTHER */
.title {
  font-weight: bold;
  font-size: 25px;
  margin-bottom: 20px;
}
</style>